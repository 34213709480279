/* ============================================================================
   Component: GUA
   ========================================================================= */

$gua-main-right-background: #f8f9fb !default;
$gua-selected-user-item-background: #f8f9fb !default;

#app-detail-collaborators .app-collaborators-info,
#app-detail-collaborators .app-add-collaborators {
	display: inline-block;
	margin: 0 12px 0 0;
}

#app-detail-collaborators .app-collaborators-info {
	background-color: $color-mercury;
	border-radius: 4px;
	border: 1px solid $color-mercury;
	padding: 3px 12px;
	font-size: 18px;
	line-height: initial;
}

#app-detail-collaborators .app-add-user-icon {
	margin-top: 0;
}

/*>> Global User Assignment*/
@keyframes loadicon {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

.gua-popup-outer {
	.mfp-container {
		position: fixed;
	}
}

.gua-base {
	@extend .white-popup-block;
	max-width: 600px;
	width: 95%;
	// height: 700px;
	margin-top: 0;
	margin-bottom: 0;
	user-select: none;

	.mfp-close-changes-happends {
		display: none;
	}

	.gua-header {
		@include single-transition(all);
		font-family: $base-font-family;
		font-weight: $regular;
		color: $color-primary;
		font-size: 24px;
		line-height: 24px;
		height: 32px;
		padding: 10px 15px 5px;
		margin-bottom: 5px;
	}

	.gua-description {
		margin: 0;
		padding-left: 15px;
		font-size: 14px;
		height: 25px;
		line-height: 18px;
		overflow: hidden;
		white-space: nowrap;
	}

	.gua-search-bar {
		margin: 0 0 10px 0;
		padding-left: 0;
		padding-right: 0;

		.gua-searchfield-outer input {
			margin: 0;
			padding-left: 32px;
		}

		.gua-search-field {
			display: flex;
			justify-content: center;

			.custom {
				@include single-transition(all);
				flex: 1;
				opacity: 1;
			}

			.gua-search-disabled {
				opacity: 0;
			}

			.gua-new-user-icon {
				padding-left: 10px;
				color: $color-primary;
				cursor: pointer;

				&:before {
					font-size: 36px;
					vertical-align: middle;
				}
			}
		}

		.font-icon-suche {
			&:before {
				position: absolute;
				top: 8px;
				left: 6px;
				color: $color-border-grey;
			}
		}

		&-searchmode {
			margin: 8px 15px 10px;

			.font-icon-suche {
				&:before {
					top: 16px;
					left: 22px;
				}
			}
		}
	}

	.gua-sub-header {
		font-size: 14px;
		padding: 7px 5px 0 15px;
		font-size: $base-font-size;
		font-weight: $semi-bold;
		color: $color-grey;
		margin: 0;
		border: none;
		white-space: nowrap;
	}

	.gua-top-bar {
		position: relative;
		top: 18px;

		.gua-header {
			@extend .gua-sub-header;
			color: $color-blue;
		}
	}

	.gua-scrollpane {
		@include single-transition(all, 800ms);
		overflow: auto;
		clear: both;
		opacity: 1;

		.gua-empty-user-list-message {
			padding: 10px 15px;
		}

		.gua-inner {
			padding-left: 0;
			margin: 0;
			list-style: none;
		}

		&-searchmode {
			opacity: 0;
		}
	}

	.gua-main-left {
		@include single-transition(all);
		margin: 0;
		padding: 0;

		.gua-left {
			padding: 0 emCalc(15px);
			margin-top: 0;
			margin-bottom: 0;
			overflow: visible;

			.gua-scrollpane {
				height: 278px;

				@media only screen and (max-width: $break-small) {
					height: 278px;
				}

				clear: both;
				margin: 0 -15px;

				.gua-inner .loading-sign {
					position: absolute;
					left: 50%;
					top: 240px;
					animation: loadicon 1s ease-in-out 0s infinite;
					font-size: 50px;
					color: #ccc;
					margin: 0 0 0 -40px;
					padding: 0 0 0 4px;
					letter-spacing: 0;
				}

				.gua-list-title {
					padding: 0 15px;
				}
			}

			.gua-search-modal-pane {
				@include single-transition(all);
				background-color: $color-light;
				margin: 0;
				position: relative;
				height: 45px;
				z-index: 10000;
				overflow: hidden;

				&-active {
					margin: -60px -15px -518px;
					height: 598px;

					.gua-search-field {
						.custom {
							flex: 1;
						}

						.gua-new-user-icon {
							display: none;
						}
					}
				}

				.gua-search-scrollpane {
					height: 415px;
					margin: 0;

					.gua-init-search-message,
					.gua-search-notfound {
						padding: 10px 15px;
					}

					.gua-user-item {
						margin-left: 15px;
					}

					.gua-app-item {
						.gua-top-bar {
							top: 0;
						}

						.gua-user-item {
							margin-left: 7px;
						}
					}
				}

				.gua-buttons {
					text-align: right;
					border-top: 1px solid $color-border-grey;
					padding: 5px 8px 0 0;
				}
			}

			.gua-search-header {
				@extend .gua-header;
				height: 0;
				padding: 0 15px 0;
				overflow: hidden;

				&-searchmode {
					height: 40px;
					padding: 10px 15px;
				}
			}
		}

		.gua-new-user-apply.gua-new-user-ready {
			opacity: 1;
			cursor: pointer;
		}

		.gua-new-user-form-shell {
			@include single-transition(all);
			position: relative;
			z-index: 10000;
			height: 0;
			overflow: hidden;
			background-color: $color-light;

			&-active {
				height: 292px;
				margin-top: -292px;
			}
		}

		.gua-new-user-form-shell-active.gua-new-user-form-shell-changes-happends {
			height: 332px;
			margin-top: -332px;
		}

		.gua-new-user-apply-buttons {
			text-align: right;
			margin: 25px 0 0;
		}

		.gua-new-user-apply {
			opacity: 0.3;
			cursor: default;
			margin-right: 0;
		}

		&-searchmode {
			height: 520px;
		}
	}

	.gua-addons {
		text-align: right;
		margin: 0 0 -6px;

		.gua-user-select-all {
			white-space: nowrap;
			font-size: 14px;
			color: $color-primary;
			cursor: hand;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.gua-user-options {
		margin-top: 10px;
	}

	.gua-add-new-user {
		padding: 0 0 15px 0;
	}

	.gua-new-user-fields {
		padding: 0 15px;

		input,
		select {
			border-radius: 0;
		}
	}

	.gua-buttons {

		height: 45px;
		margin: 0;
		padding: 2px 0 0;
	}

	.gua-hidden-buttons {
		@include single-transition(all);
		text-align: right;
		height: 0;
		overflow: hidden;

		&-show {
			border-top: 1px solid $color-border-grey;
			height: 45px;
			padding-top: 5px;
		}
	}

	.gua-app-item {
		position: relative;
		margin: 30px 0 0;

		.gua-app-item-bar {
			background-color: $color-mercury-light;
			padding: 6px;
		}

		.gua-app-item-inner {
			margin: 0 0 0 8px;
		}

		.gua-icon {
			position: absolute;
			top: 4px;
			left: 14px;
			width: 38px;
			height: 38px;
			background: url(/assets/livingapps/app-placeholder.jpg) center no-repeat;
			background-size: 38px 38px;
			border-radius: 5px;
		}

		.gua-badge {
			position: absolute;
			left: 31px;
			top: 1px;
			z-index: 10;
			color: #fff;
			font-size: 10px;
			font-weight: $semi-bold;
			line-height: 10px;
			padding: 1px 4px;
			background-color: #ea3a4c;
			border-radius: 6px;
		}

		.gua-title {
			padding: 7px 0 9px 52px;
			font-size: $base-font-size;
			font-weight: bold;
			line-height: 18px;
		}
	}

	.gua-user-item {
		display: flex;
		align-items: center;
		margin: 8px 0;
		padding: 0 15px;

		.gua-section-left {
			flex: auto;
		}

		.gua-section-right {
			flex: 0;
			padding: 0 10px;
		}

		.gua-section-left-row {
			display: flex;
			align-items: center;
		}

		.gua-user-image {
			width: 41px;
			padding: 0;
			flex: none;
		}

		.gua-user-image img {
			max-width: inherit;
			width: 36px;
			height: 36px;
			border-radius: 24px;
		}

		.gua-user-name {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0;

			&-inner {
				margin-right: 5px;
			}
		}

		.gua-user-email {
			display: block;
			font-size: 13px;
			margin: -5px 0 0;
		}

		.gua-user-access {
			margin: 0 0 0 auto;
		}

		.gua-user-rights {
			visibility: hidden;
			padding: 10px 5px 0 0;
			font-size: 11px;
		}

		.gua-user-rights-visible {
			visibility: visible;
		}

		.gua-user-add-symbol-outer {
			border: 2px solid $color-border-grey;
			border-radius: 4px;
			width: 24px;
			height: 24px;
			padding: 0 0 0 1px;
		}

		.gua-user-add-symbol {
			font-size: 12px;
			width: 20px;
			height: 20px;
			color: $color-primary;
			padding: 1px 0 0;
			text-align: center;
			cursor: hand;
			cursor: pointer;

			&:before {
				font-weight: bold;
			}
		}

		.gua-user-add-symbol-selected {
			@extend .font-icon-check !optional;
		}

		.gua-user-item-message {
			font-size: 12px;
			color: $color-grey;
			white-space: nowrap;
			margin-right: 5px;
		}

		.gua-user-item-message:before {
			color: $color-orange-light;
		}

		.gua-user-item-decontrol {
			display: flex;
			align-items: center;
			margin-top: -2px;

			.gua-little-lock {
				height: 23px;
				margin-left: -3px;

				&:before {
					font-size: 19px;
					color: $color-grey;
				}
			}

			.gua-little-right-arrow {
				height: 9px;

				&:before {
					font-size: 9px;
					color: $color-grey;
					margin-left: -2px;
				}
			}

			select {
				margin-left: 5px;
				appearance: none;
				width: auto;
				border: none;
				background: transparent;
				font-size: 13px;
				color: $color-primary;
				font-weight: normal;
				text-decoration: underline;
				outline: none;
				cursor: pointer;
			}

			@media only screen and (max-width: $break-small) {
				.gua-little-lock {
					height: 20px;
				}

				select {
					font-size: 16px;
				}
			}
		}
	}
}

/*<<*/