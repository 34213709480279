/* ============================================================================
   Component: Panel
   ========================================================================= */

$panel-background: $color-light !default;
$panel-grey-background: $color-mercury-light !default;
$panel-blue-background: $color-category-blue !default;
$panel-orange-background: $color-category-orange !default;
$panel-cyan-background: $color-category-cyan !default;
$panel-pink-background: $color-category-pink !default;

@mixin with-panel-box() {
	position: relative;
	padding: rem-calc(20px);
	background: $panel-background;
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
	@include single-transition(box-shadow);
}

@mixin without-panel-box() {
	padding: 0;
	background: inherit;
	border-radius: 0;
	box-shadow: none;
}

.panel {
	@include with-panel-box();

	.content {
		margin-top: rem-calc(25px);
	}

	.headline {
		font-size: rem-calc(20px);
		font-weight: $regular;
	}

	.description {
		font-size: rem-calc(14px);
	}

	&__input {
		margin-top: rem-calc(15px);
	}

	.image {
		text-align: center;

		img {
			border-radius: 4px;
			width: 100%;
			max-width: 135px;
			max-height: 100%;
		}
	}

	.buttons {
		margin-top: rem-calc(5px);
	}

	.footer {
		font-size: rem-calc(14px);
		line-height: rem-calc(24px);
		color: $color-gray-chateau;
		margin-top: rem-calc(5px);
	}

	.edit-button {
		color: $color-iron-dark;

		&:hover {
			color: $color-iron-x-dark;
		}
	}

	&.panel-clear {
		padding: 0;
		background: none;
		box-shadow: none;
	}

	&.panel-style-2 {
		.content {
			margin-top: rem-calc(10px);
		}

		.headline {
			font-size: rem-calc(24px);
		}

		.description {
			font-size: rem-calc(16px);
		}

		.image .font-icon:before {
			font-size: 80px;
			line-height: 70px;
			color: $color-iron-dark;
		}

		button,
		.button {
			margin: 0;
		}
	}

	&.panel-linkpanel {
		.headline {
			font-size: rem-calc(24px);
		}
	}

	&--app-package {
		.headline {
			font-size: rem-calc(24px);
		}

		.row .row .columns {
			margin-top: 0;
			margin-bottom: 0;
		}

		.input-icon-right {
			top: 7px;
			right: 7px;
		}

		.has-input-icon-right {
			input {
				padding-right: 35px;
			}
		}
	}

	&--linkpanel {
		overflow: hidden;
		padding: 0;

		.title-outer {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: baseline;
			width: fit-content;
			text-decoration: none;

			.title,
			.lnk-icon {
				font-size: rem-calc(24px);
			}
		}

		a.title-outer {
			.title,
			.lnk-icon {
				color: #008bd2;
			}

			&:hover {
				.title,
				.lnk-icon {
					color: #0070a9;
				}
			}
		}

		.panel.panel--linkpanel .row {

			&::before,
			&::after {
				display: block;
			}
		}

		.la-card,
		.la-header {
			display: flex;
			background-size: cover;
			background-repeat: no-repeat;
			flex-direction: column;
			justify-content: flex-end;
			text-decoration: none;

			.title-outer {
				padding: .6rem 1.2rem .2rem;
				width: auto;
				transition: all .2s ease-in-out;

				.title,
				.lnk-icon {
					color: #fff;
					font-size: rem-calc(24px);
					text-decoration: none;
				}
			}
		}

		.la-card {
			min-height: 150px;

			.title-outer {
				background-color: rgba(0, 0, 0, .3);
			}
		}

		a.la-card:hover {
			.title-outer {
				padding-bottom: rem-calc(24px);
			}
		}

		.la-simpleheader {
			margin: rem-calc(16px) rem-calc(20px) rem-calc(12px);
		}

		.description {
			margin: rem-calc(12px) rem-calc(20px) rem-calc(16px);
			line-height: 1.4;
			font-size: rem-calc(16px);

			p {
				margin: 0 0 0.5em;
			}

			ul {
				margin: 0 0 0.5em;
				list-style-type: disc;
			}
		}

		.linkpanelchildren & {
			border-radius: 0;
			box-shadow: none;
			overflow: inherit;

			.la-simpleheader {
				margin:rem-calc(6px) rem-calc(20px);

				.title-outer {
					.title,
					.lnk-icon {
						font-size: rem-calc(16px);
					}
				}
			}

			.description--simpleheader {
				margin:rem-calc(6px) rem-calc(20px);
			}
		}

		&--mylivingapps-overview-top {
			@include without-panel-box();

			h4 {
				font-size: rem-calc(24px);
			}

			.description {
				font-size: rem-calc(18px);
			}
		}
	}

	&--without-box {
		@include without-panel-box();
	}

	&--iframe {
		padding: 0;
		overflow: auto;
	}

	&.text-white {

		.headline,
		.description,
		.image .font-icon:before {
			color: $color-light;
		}

		.edit-button {
			color: $color-light;
			opacity: 0.6;
			@include single-transition(opacity);

			&:hover {
				opacity: 0.8;
			}
		}
	}

	&.grey {
		background: $panel-grey-background;
	}

	&.blue {
		background: $panel-blue-background;
	}

	&.category-orange {
		background: $panel-orange-background;
	}

	&.category-cyan {
		background: $panel-cyan-background;
	}

	&.category-pink {
		background: $panel-pink-background;
	}
}