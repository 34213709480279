/* ============================================================================
   Abstract: Variables
   ========================================================================= */

/**
 * Base
 */
$base-font-size: 16px !default;
$base-font-family: 'Source Sans Pro', sans-serif !default;
$icon-url: url(~img/icons.png) !default;
$image-path: '~img' !default;
$iframe-fullsize: false !default;

/**
 * Font Weights
 */
$extra-light: 200;
$regular: 400;
$semi-bold: 600;

/**
 * Main Color Variables
 */
$color-std: $color-dark;
$color-primary: $color-blue;
$color-primary-hover: $color-blue-dark;
$color-secondary: null;
$color-secondary-hover: null;

/**
 * Colors with specified element
 */
$color-headline-color: $color-dark;
$color-headline-weight: $regular;
$color-link-color: $color-primary;
$color-link-hover-color: $color-primary-hover;
$color-border-grey: $color-mercury;

/**
 * Boxes
 */
$color-greenbox-bg: #e3f1a9;
$color-greenbox-font: #67882b;
$color-greybox: #f6f6f6;
$color-greybox-title: #929598;
$color-light-greybox: $color-athens-gray;
$color-redbox: #b82a2a;
$color-redbox-bg: #f7e7e7;
$color-redbox-border: #b82a2a;
$color-redbox-icon: #f4acac;

/**
 * Grid
 */
$grid-gutter: emCalc(10px);
$grid-width: rem-calc(1440px);
$grid-gutter-pixel: 10px;
$grid-top-bottom: 0.5em;
$grid-gutter-remove: emCalc(-10px);
$grid-alignment: 0 auto;

/**
 * Breakpoints
 */
$break-small: 639px;
$break-medium: 960px;

/**
 * font awesome overrides
 */
$fa-font-path: "font";

/**
 * Icon sprite
 */
$icons-sprite-total-width: 300px;
$icons-sprite-total-height: 140px;

$icons-sprite-lock-width: 12px;
$icons-sprite-lock-height: 15px;
$icons-sprite-lock-std-pos: 0 -50px;
$icons-sprite-lock-hover-pos: -50px -50px;

$icons-sprite-zoom-width: 15px;
$icons-sprite-zoom-height: 16px;
$icons-sprite-zoom-std-pos: -25px -25px;
$icons-sprite-zoom-hover-pos: -75px -25px;

$icons-sprite-e-blue-pos: 0 -100px;
$icons-sprite-w-blue-pos: 0 -125px;
$icons-sprite-e-end-blue-pos: -25px -100px;
$icons-sprite-w-end-blue-pos: -25px -125px;

$icons-sprite-we-width: 7px;
$icons-sprite-we-height: 11px;
$icons-sprite-we-end-width: 10px;

/**
 * Import page colors
 */

$import-grey: $color-athens-gray;
$import-primary-color: $color-primary;
$import-button-color-red: $color-alizarin-crimson;

/**
 * Evaluation page colors
 */

$evaluation-calendar-event-background-color: $color-primary;
$evaluation-calendar-event-border-color: $evaluation-calendar-event-background-color;