/* ============================================================================
   Component: Typed Views Tabs. Typed views are special formbuilder view representations.
   ========================================================================= */


#livingapps-form .typed-views-tabs {
	border: none;

	ul {
		background: transparent;
		border-bottom: 1px solid $color-border-grey;
		margin: 3px 0 30px;
		height: 30px;

		li {
			margin-right: 3px;
			background: transparent;

			a {
				font-size: 1rem;
				color: $color-dark;
				padding: 2px 14px;
				background: $color-iron;
				border-radius: 4px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		li.ui-tabs-active {
			a {
				color: $color-light;
				background: $color-primary;
				padding: 2px 14px 5px;
				margin-top: -3px;
			}
		}
	}
}

@media screen and (max-width: $break-small) {}