/* >> Zurb */
#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
	max-width: none !important;
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-justify {
	text-align: justify !important;
}

.antialiased {
	-webkit-font-smoothing: antialiased;
}

.row {
	margin: 0 20px;
	// max-width: $grid-width;
	*zoom: 1;
}

.pageStyle_login .row,
.pageStyle_pwdconfirm .row,
.pageStyle_pwdrecovery .row,
.pageStyle_register .row,
.pageStyle_registerconfirm .row,
.pageStyle_contactForm .row,
.pageStyle_services .row,
.pageStyle_user-management .row {
	max-width: $grid-width;
	margin: 0 auto;
}

.mylivingapps-overview-wrapper,
.mylivingapps-inputdata {
	max-width: 2000px;
	margin: 0 auto;
	padding: 0 20px;
}

.mylivingapps-inputdata {
	max-width: 1500px;
}

.row:before,
.row:after {
	content: " ";
	display: table;
}

.row:after {
	clear: both
}

%row_in_row {
	width: auto;
	margin-left: $grid-gutter-remove;
	margin-right: $grid-gutter-remove;
	max-width: none;
	*zoom: 1;
}

.contentStyle_rightTeaser .content .row,
.contentStyle_leftTeaser .content .row,
.row .row {
	@extend %row_in_row;
}

.contentStyle_rightTeaser .content .row,
.contentStyle_leftTeaser .content .row {
	// max-width: $grid-width;
}

.row .row.collapse {
	margin: 0;
}

%columns {
	position: relative;
	padding-left: $grid-gutter;
	padding-right: $grid-gutter;
	width: 100%;
	float: left;
	margin-top: $grid-top-bottom;
	margin-bottom: $grid-top-bottom;
}

.columns {
	@extend %columns;
}

.row.collapse .columns {
	padding-left: 0;
	padding-right: 0;
}

.show-for-small,
.show-for-medium-down,
.show-for-large-down {
	display: inherit !important
}

.show-for-medium,
.show-for-medium-up,
.show-for-large,
.show-for-large-up,
.show-for-xlarge {
	display: none !important
}

.hide-for-medium,
.hide-for-medium-up,
.hide-for-large,
.hide-for-large-up,
.hide-for-xlarge {
	display: inherit !important
}

.hide-for-small,
.hide-for-medium-down,
.hide-for-large-down {
	display: none !important
}

table.show-for-small,
table.show-for-medium-down,
table.show-for-large-down,
table.hide-for-medium,
table.hide-for-medium-up,
table.hide-for-large,
table.hide-for-large-up,
table.hide-for-xlarge {
	display: table
}

thead.show-for-small,
thead.show-for-medium-down,
thead.show-for-large-down,
thead.hide-for-medium,
thead.hide-for-medium-up,
thead.hide-for-large,
thead.hide-for-large-up,
thead.hide-for-xlarge {
	display: table-header-group !important
}

tbody.show-for-small,
tbody.show-for-medium-down,
tbody.show-for-large-down,
tbody.hide-for-medium,
tbody.hide-for-medium-up,
tbody.hide-for-large,
tbody.hide-for-large-up,
tbody.hide-for-xlarge {
	display: table-row-group !important
}

tr.show-for-small,
tr.show-for-medium-down,
tr.show-for-large-down,
tr.hide-for-medium,
tr.hide-for-medium-up,
tr.hide-for-large,
tr.hide-for-large-up,
tr.hide-for-xlarge {
	display: table-row !important
}

td.show-for-small,
td.show-for-medium-down,
td.show-for-large-down,
td.hide-for-medium,
td.hide-for-medium-up,
td.hide-for-large,
td.hide-for-large-up,
td.hide-for-xlarge,
th.show-for-small,
th.show-for-medium-down,
th.show-for-large-down,
th.hide-for-medium,
th.hide-for-medium-up,
th.hide-for-large,
th.hide-for-large-up,
th.hide-for-xlarge {
	display: table-cell !important
}

/* IE8-Fix The Grid ---------------------- */
.lt-ie9 .row {
	width: 960px;
	max-width: 100%;
	min-width: 768px;
	margin-left: auto;
	margin-right: auto;
}

.lt-ie9 .row .row {
	width: auto;
	max-width: none;
	min-width: 0;
	margin-left: $grid-gutter-remove;
	margin-right: $grid-gutter-remove;
}

.lt-ie9 .row.large-collapse .columns,
.lt-ie9 .row.collapse .columns {
	padding: 0;
}

.lt-ie9 .row .row.large-collapse,
.lt-ie9 .row .row.collapse {
	margin: 0;
}

.lt-ie9 .columns {
	float: left;
	min-height: 1px;
	padding-left: $grid-gutter;
	padding-right: $grid-gutter;
	position: relative;
}

.lt-ie9 .columns.large-centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.lt-ie9 [class*="column"]+[class*="column"]:last-child {
	float: right;
}

.lt-ie9 [class*="column"]+[class*="column"].end {
	float: left;
}

.lt-ie9 .large-1 {
	width: 8.33333%;
}

.lt-ie9 .large-2 {
	width: 16.66667%;
}

.lt-ie9 .large-3 {
	width: 25%;
}

.lt-ie9 .large-4 {
	width: 33.33333%;
}

.lt-ie9 .large-5 {
	width: 41.66667%;
}

.lt-ie9 .large-6 {
	width: 50%;
}

.lt-ie9 .large-7 {
	width: 58.33333%;
}

.lt-ie9 .large-8 {
	width: 66.66667%;
}

.lt-ie9 .large-9 {
	width: 75%;
}

.lt-ie9 .large-10 {
	width: 83.33333%;
}

.lt-ie9 .large-11 {
	width: 91.66667%;
}

.lt-ie9 .large-12 {
	width: 100%;
}

.lt-ie9 .large-offset-1 {
	margin-left: 8.33333%;
}

.lt-ie9 .large-offset-2 {
	margin-left: 16.66667%;
}

.lt-ie9 .large-offset-3 {
	margin-left: 25%;
}

.lt-ie9 .large-offset-4 {
	margin-left: 33.33333%;
}

.lt-ie9 .large-offset-5 {
	margin-left: 41.66667%;
}

.lt-ie9 .large-offset-6 {
	margin-left: 50%;
}

.lt-ie9 .large-offset-7 {
	margin-left: 58.33333%;
}

.lt-ie9 .large-offset-8 {
	margin-left: 66.66667%;
}

.lt-ie9 .large-offset-9 {
	margin-left: 75%;
}

.lt-ie9 .large-offset-10 {
	margin-left: 83.33333%;
}

.lt-ie9 .pull-2 {
	right: 16.66667%;
}

.lt-ie9 .pull-3 {
	right: 25%;
}

.lt-ie9 .pull-4 {
	right: 33.33333%;
}

.lt-ie9 .pull-5 {
	right: 41.66667%;
}

.lt-ie9 .pull-6 {
	right: 50%;
}

.lt-ie9 .pull-7 {
	right: 58.33333%;
}

.lt-ie9 .pull-8 {
	right: 66.66667%;
}

.lt-ie9 .pull-9 {
	right: 75%;
}

.lt-ie9 .pull-10 {
	right: 83.33333%;
}

.lt-ie9 .push-2 {
	left: 16.66667%;
}

.lt-ie9 .push-3 {
	left: 25%;
}

.lt-ie9 .push-4 {
	left: 33.33333%;
}

.lt-ie9 .push-5 {
	left: 41.66667%;
}

.lt-ie9 .push-6 {
	left: 50%;
}

.lt-ie9 .push-7 {
	left: 58.33333%;
}

.lt-ie9 .push-8 {
	left: 66.66667%;
}

.lt-ie9 .push-9 {
	left: 75%;
}

.lt-ie9 .push-10 {
	left: 83.33333%;
}

.lt-ie9 .hide-for-small {
	display: inherit !important;
}

.lt-ie9 .footer span.hide-for-small {
	display: inline !important;
}

.lt-ie9 .show-for-small {
	display: none !important;
}

/* Small Block Grids */
.lt-ie9 .small-block-grid-1,
.lt-ie9 .small-block-grid-2,
.lt-ie9 .small-block-grid-3,
.lt-ie9 .small-block-grid-4,
.lt-ie9 .small-block-grid-5,
.lt-ie9 .small-block-grid-6,
.lt-ie9 .small-block-grid-7,
.lt-ie9 .small-block-grid-8,
.lt-ie9 .small-block-grid-9,
.lt-ie9 .small-block-grid-10,
.lt-ie9 .small-block-grid-11,
.lt-ie9 .small-block-grid-12 {
	list-style: none;
}

.lt-ie9 .small-block-grid-1 li,
.lt-ie9 .small-block-grid-2 li,
.lt-ie9 .small-block-grid-3 li,
.lt-ie9 .small-block-grid-4 li,
.lt-ie9 .small-block-grid-5 li,
.lt-ie9 .small-block-grid-6 li,
.lt-ie9 .small-block-grid-7 li,
.lt-ie9 .small-block-grid-8 li,
.lt-ie9 .small-block-grid-9 li,
.lt-ie9 .small-block-grid-10 li,
.lt-ie9 .small-block-grid-11 li,
.lt-ie9 .small-block-grid-12 li {
	float: left;
	padding: 0 10px 10px;
}

.lt-ie9 .small-block-grid-1 li:first-child,
.lt-ie9 .small-block-grid-2 li:first-child+li,
.lt-ie9 .small-block-grid-3 li:first-child+li+li,
.lt-ie9 .small-block-grid-4 li:first-child+li+li+li,
.lt-ie9 .small-block-grid-5 li:first-child+li+li+li+li,
.lt-ie9 .small-block-grid-6 li:first-child+li+li+li+li+li,
.lt-ie9 .small-block-grid-7 li:first-child+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-8 li:first-child+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-9 li:first-child+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-10 li:first-child+li+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-11 li:first-child+li+li+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-12 li:first-child+li+li+li+li+li+li+li+li+li+li+li {
	float: right
}

.lt-ie9 .small-block-grid-1 li:first-child+li,
.lt-ie9 .small-block-grid-2 li:first-child+li+li,
.lt-ie9 .small-block-grid-3 li:first-child+li+li+li,
.lt-ie9 .small-block-grid-4 li:first-child+li+li+li+li,
.lt-ie9 .small-block-grid-5 li:first-child+li+li+li+li+li,
.lt-ie9 .small-block-grid-6 li:first-child+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-7 li:first-child+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-8 li:first-child+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-9 li:first-child+li+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-10 li:first-child+li+li+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-11 li:first-child+li+li+li+li+li+li+li+li+li+li+li,
.lt-ie9 .small-block-grid-12 li:first-child+li+li+li+li+li+li+li+li+li+li+li+li {
	clear: both
}

.lt-ie9 .small-block-grid-1>li {
	width: 100%;
}

.lt-ie9 .small-block-grid-2>li {
	width: 50%;
}

.lt-ie9 .small-block-grid-3>li {
	width: 33.33333%;
}

.lt-ie9 .small-block-grid-4>li {
	width: 25%;
}

.lt-ie9 .small-block-grid-5>li {
	width: 20%;
}

.lt-ie9 .small-block-grid-6>li {
	width: 16.66667%;
}

.lt-ie9 .small-block-grid-7>li {
	width: 14.28571%;
}

.lt-ie9 .small-block-grid-8>li {
	width: 12.5%;
}

.lt-ie9 .small-block-grid-9>li {
	width: 11.11111%;
}

.lt-ie9 .small-block-grid-10>li {
	width: 10%;
}

.lt-ie9 .small-block-grid-11>li {
	width: 9.09091%;
}

.lt-ie9 .small-block-grid-12>li {
	width: 8.33333%;
}

.lt-ie8 .jsSliderShell .multiCol_4 .paragraphOuter {
	width: 205px;
}

@media print,
screen {

	.small-1,
	.small-2,
	.small-3,
	.small-4,
	.small-5,
	.small-6,
	.small-7,
	.small-8,
	.small-9,
	.small-10,
	.small-11,
	.small-12,
	.small-offset-0,
	.small-offset-1,
	.small-offset-2,
	.small-offset-3,
	.small-offset-4,
	.small-offset-5,
	.small-offset-6,
	.small-offset-7,
	.small-offset-8,
	.small-offset-9,
	.small-offset-10 {
		position: relative;
	}

	.small-1 {
		width: 8.33333%;
	}

	.small-2 {
		width: 16.66667%;
	}

	.small-3 {
		width: 25%;
	}

	.small-4 {
		width: 33.33333%;
	}

	.small-5 {
		width: 41.66667%;
	}

	.small-6 {
		width: 50%;
	}

	.small-7 {
		width: 58.33333%;
	}

	.small-8 {
		width: 66.66667%;
	}

	.small-9 {
		width: 75%;
	}

	.small-10 {
		width: 83.33333%;
	}

	.small-11 {
		width: 91.66667%;
	}

	.small-12 {
		width: 100%;
	}

	.small-offset-0 {
		margin-left: 0;
	}

	.small-offset-1 {
		margin-left: 8.33333%;
	}

	.small-offset-2 {
		margin-left: 16.66667%;
	}

	.small-offset-3 {
		margin-left: 25%;
	}

	.small-offset-4 {
		margin-left: 33.33333%;
	}

	.small-offset-5 {
		margin-left: 41.66667%;
	}

	.small-offset-6 {
		margin-left: 50%;
	}

	.small-offset-7 {
		margin-left: 58.33333%;
	}

	.small-offset-8 {
		margin-left: 66.66667%;
	}

	.small-offset-9 {
		margin-left: 75%;
	}

	.small-offset-10 {
		margin-left: 83.33333%;
	}

	[class*="column"]+[class*="column"]:last-child {}

	[class*="column"]+[class*="column"].end {
		float: left
	}

	.columns.small-centered {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		float: none !important;
	}

	[class*="block-grid-"] {
		display: block;
		padding: 0;
		margin: 0 $grid-gutter-remove;
		*zoom: 1;
	}

	[class*="block-grid-"]:before,
	[class*="block-grid-"]:after {
		content: " ";
		display: table;
	}

	[class*="block-grid-"]:after {
		clear: both
	}

	[class*="block-grid-"]>li {
		display: inline;
		height: auto;
		float: left;
		padding: 0 $grid-gutter 2*$grid-gutter;
	}

	.small-block-grid-1>li,
	.small-block-grid-2>li,
	.small-block-grid-3>li,
	.small-block-grid-4>li,
	.small-block-grid-5>li,
	.small-block-grid-6>li,
	.small-block-grid-7>li,
	.small-block-grid-8>li,
	.small-block-grid-9>li,
	.small-block-grid-10>li,
	.small-block-grid-11>li,
	.small-block-grid-12>li {
		padding: 0 $grid-gutter 2*$grid-gutter;
	}

	.small-block-grid-1>li:nth-of-type(n),
	.small-block-grid-2>li:nth-of-type(n),
	.small-block-grid-3>li:nth-of-type(n),
	.small-block-grid-4>li:nth-of-type(n),
	.small-block-grid-5>li:nth-of-type(n),
	.small-block-grid-6>li:nth-of-type(n),
	.small-block-grid-7>li:nth-of-type(n),
	.small-block-grid-8>li:nth-of-type(n),
	.small-block-grid-9>li:nth-of-type(n),
	.small-block-grid-10>li:nth-of-type(n),
	.small-block-grid-11>li:nth-of-type(n),
	.small-block-grid-12>li:nth-of-type(n) {
		clear: none
	}

	.small-block-grid-1>li:nth-of-type(1n+1),
	.small-block-grid-2>li:nth-of-type(2n+1),
	.small-block-grid-3>li:nth-of-type(3n+1),
	.small-block-grid-4>li:nth-of-type(4n+1),
	.small-block-grid-5>li:nth-of-type(5n+1),
	.small-block-grid-6>li:nth-of-type(6n+1),
	.small-block-grid-7>li:nth-of-type(7n+1),
	.small-block-grid-8>li:nth-of-type(8n+1),
	.small-block-grid-9>li:nth-of-type(9n+1),
	.small-block-grid-10>li:nth-of-type(10n+1),
	.small-block-grid-11>li:nth-of-type(11n+1),
	.small-block-grid-12>li:nth-of-type(12n+1) {
		clear: both
	}

	.small-block-grid-1>li {
		width: 100%;
	}

	.small-block-grid-2>li {
		width: 50%;
	}

	.small-block-grid-3>li {
		width: 33.33333%;
	}

	.small-block-grid-4>li {
		width: 25%;
	}

	.small-block-grid-5>li {
		width: 20%;
	}

	.small-block-grid-6>li {
		width: 16.66667%;
	}

	.small-block-grid-7>li {
		width: 14.28571%;
	}

	.small-block-grid-8>li {
		width: 12.5%;
	}

	.small-block-grid-9>li {
		width: 11.11111%;
	}

	.small-block-grid-10>li {
		width: 10%;
	}

	.small-block-grid-11>li {
		width: 9.09091%;
	}

	.small-block-grid-12>li {
		width: 8.33333%;
	}
}


@media print,
screen and (min-width: 640px) {

	.show-for-medium,
	.show-for-medium-up {
		display: inherit !important
	}

	.show-for-small {
		display: none !important
	}

	.hide-for-small {
		display: inherit !important
	}

	.bottomLine .hide-for-small {
		display: inline !important;
	}

	.hide-for-medium,
	.hide-for-medium-up {
		display: none !important
	}

	/* Specific visilbity for tables */
	table.show-for-medium,
	table.show-for-medium-up,
	table.hide-for-small {
		display: table
	}

	thead.show-for-medium,
	thead.show-for-medium-up,
	thead.hide-for-small {
		display: table-header-group !important
	}

	tbody.show-for-medium,
	tbody.show-for-medium-up,
	tbody.hide-for-small {
		display: table-row-group !important
	}

	tr.show-for-medium,
	tr.show-for-medium-up,
	tr.hide-for-small {
		display: table-row !important
	}

	td.show-for-medium,
	td.show-for-medium-up,
	td.hide-for-small,
	th.show-for-medium,
	th.show-for-medium-up,
	th.hide-for-small {
		display: table-cell !important
	}

	.medium-push-1,
	.medium-push-2,
	.medium-push-3,
	.medium-push-4,
	.medium-push-5,
	.medium-push-6,
	.medium-push-7,
	.medium-push-8,
	.medium-push-9,
	.medium-push-10,
	.medium-push-11 {
		right: auto;
	}

	.medium-pull-1,
	.medium-pull-2,
	.medium-pull-3,
	.medium-pull-4,
	.medium-pull-5,
	.medium-pull-6,
	.medium-pull-7,
	.medium-pull-8,
	.medium-pull-9,
	.medium-pull-10,
	.medium-pull-11 {
		left: auto;
	}

	.medium-1 {
		width: 8.33333%;
	}

	.medium-2 {
		width: 16.66667%;
	}

	.medium-3 {
		width: 25%;
	}

	.medium-4 {
		width: 33.33333%;
	}

	.medium-5 {
		width: 41.66667%;
	}

	.medium-6 {
		width: 50%;
	}

	.medium-7 {
		width: 60.33333%;
	}

	.medium-8 {
		width: 66.66667%;
	}

	.medium-9 {
		width: 75%;
	}

	.medium-10 {
		width: 83.33333%;
	}

	.medium-11 {
		width: 91.66667%;
	}

	.medium-12 {
		width: 100%;
	}

	.medium-offset-0 {
		margin-left: 0;
	}

	.medium-offset-1 {
		margin-left: 8.33333%;
	}

	.medium-offset-2 {
		margin-left: 16.66667%;
	}

	.medium-offset-3 {
		margin-left: 25%;
	}

	.medium-offset-4 {
		margin-left: 33.33333%;
	}

	.medium-offset-5 {
		margin-left: 41.66667%;
	}

	.medium-offset-6 {
		margin-left: 50%;
	}

	.medium-offset-7 {
		margin-left: 58.33333%;
	}

	.medium-offset-8 {
		margin-left: 66.66667%;
	}

	.medium-offset-9 {
		margin-left: 75%;
	}

	.medium-offset-10 {
		margin-left: 83.33333%;
	}

	.medium-offset-11 {
		margin-left: 91.66667%;
	}

	.medium-push-1 {
		left: 8.33333%;
	}

	.medium-pull-1 {
		right: 8.33333%;
	}

	.medium-push-2 {
		left: 16.66667%;
	}

	.medium-pull-2 {
		right: 16.66667%;
	}

	.medium-push-3 {
		left: 25%;
	}

	.medium-pull-3 {
		right: 25%;
	}

	.medium-push-4 {
		left: 33.33333%;
	}

	.medium-pull-4 {
		right: 33.33333%;
	}

	.medium-push-5 {
		left: 41.66667%;
	}

	.medium-pull-5 {
		right: 41.66667%;
	}

	.medium-push-6 {
		left: 50%;
	}

	.medium-pull-6 {
		right: 50%;
	}

	.medium-push-7 {
		left: 58.33333%;
	}

	.medium-pull-7 {
		right: 58.33333%;
	}

	.medium-push-8 {
		left: 66.66667%;
	}

	.medium-pull-8 {
		right: 66.66667%;
	}

	.medium-push-9 {
		left: 75%;
	}

	.medium-pull-9 {
		right: 75%;
	}

	.medium-push-10 {
		left: 83.33333%;
	}

	.medium-pull-10 {
		right: 83.33333%;
	}

	.medium-push-11 {
		left: 91.66667%;
	}

	.medium-pull-11 {
		right: 91.66667%;
	}

	.columns.medium-centered {
		margin-left: auto;
		margin-right: auto;
		float: none !important;
	}

	.columns.medium-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left !important;
	}

	/* Remove small grid clearing */
	.small-block-grid-1>li:nth-of-type(1n+1),
	.small-block-grid-2>li:nth-of-type(2n+1),
	.small-block-grid-3>li:nth-of-type(3n+1),
	.small-block-grid-4>li:nth-of-type(4n+1),
	.small-block-grid-5>li:nth-of-type(5n+1),
	.small-block-grid-6>li:nth-of-type(6n+1),
	.small-block-grid-7>li:nth-of-type(7n+1),
	.small-block-grid-8>li:nth-of-type(8n+1),
	.small-block-grid-9>li:nth-of-type(9n+1),
	.small-block-grid-10>li:nth-of-type(10n+1),
	.small-block-grid-11>li:nth-of-type(11n+1),
	.small-block-grid-12>li:nth-of-type(12n+1) {
		clear: none
	}

	.medium-block-grid-1>li,
	.medium-block-grid-2>li,
	.medium-block-grid-3>li,
	.medium-block-grid-4>li,
	.medium-block-grid-5>li,
	.medium-block-grid-6>li,
	.medium-block-grid-7>li,
	.medium-block-grid-8>li,
	.medium-block-grid-9>li,
	.medium-block-grid-10>li,
	.medium-block-grid-11>li,
	.medium-block-grid-12>li {
		padding: 0 $grid-gutter 2*$grid-gutter;
	}

	.medium-block-grid-1>li:nth-of-type(n),
	.medium-block-grid-2>li:nth-of-type(n),
	.medium-block-grid-3>li:nth-of-type(n),
	.medium-block-grid-4>li:nth-of-type(n),
	.medium-block-grid-5>li:nth-of-type(n),
	.medium-block-grid-6>li:nth-of-type(n),
	.medium-block-grid-7>li:nth-of-type(n),
	.medium-block-grid-8>li:nth-of-type(n),
	.medium-block-grid-9>li:nth-of-type(n),
	.medium-block-grid-10>li:nth-of-type(n),
	.medium-block-grid-11>li:nth-of-type(n),
	.medium-block-grid-12>li:nth-of-type(n) {
		clear: none
	}

	.medium-block-grid-1>li:nth-of-type(1n+1),
	.medium-block-grid-2>li:nth-of-type(2n+1),
	.medium-block-grid-3>li:nth-of-type(3n+1),
	.medium-block-grid-4>li:nth-of-type(4n+1),
	.medium-block-grid-5>li:nth-of-type(5n+1),
	.medium-block-grid-6>li:nth-of-type(6n+1),
	.medium-block-grid-7>li:nth-of-type(7n+1),
	.medium-block-grid-8>li:nth-of-type(8n+1),
	.medium-block-grid-9>li:nth-of-type(9n+1),
	.medium-block-grid-10>li:nth-of-type(10n+1),
	.medium-block-grid-11>li:nth-of-type(11n+1),
	.medium-block-grid-12>li:nth-of-type(12n+1) {
		clear: both
	}

	.medium-block-grid-1>li {
		width: 100%;
	}

	.medium-block-grid-2>li {
		width: 50%;
	}

	.medium-block-grid-3>li {
		width: 33.33333%;
	}

	.medium-block-grid-4>li {
		width: 25%;
	}

	.medium-block-grid-5>li {
		width: 20%;
	}

	.medium-block-grid-6>li {
		width: 16.66667%;
	}

	.medium-block-grid-7>li {
		width: 14.28571%;
	}

	.medium-block-grid-8>li {
		width: 12.5%;
	}

	.medium-block-grid-9>li {
		width: 11.11111%;
	}

	.medium-block-grid-10>li {
		width: 10%;
	}

	.medium-block-grid-11>li {
		width: 9.09091%;
	}

	.medium-block-grid-12>li {
		width: 8.33333%;
	}

	.mylivingapps-overview-wrapper {
		padding: 0;

		.medium-7,
		.medium-3 {
			width: 100%;
		}
	}
}


@media print,
screen and (min-width: 960px) {

	.mylivingapps-overview-wrapper {
		padding: 0 20px;

		.medium-7 {
			width: 60.33333%;
		}

		.medium-3 {
			width: 23%;
		}
	}

	img.no-scaling,
	.no-scaling img,
	img.moveOutside,
	.moveOutside img {
		width: auto;
		max-width: none;
	}

	.show-for-large,
	.show-for-large-up {
		display: inherit !important
	}

	.show-for-medium,
	.show-for-medium-down {
		display: none !important
	}

	.hide-for-medium,
	.hide-for-medium-down {
		display: inherit !important
	}

	.hide-for-large,
	.hide-for-large-up {
		display: none !important
	}

	/* Specific visilbity for tables */
	table.show-for-large,
	table.show-for-large-up,
	table.hide-for-medium,
	table.hide-for-medium-down {
		display: table
	}

	thead.show-for-large,
	thead.show-for-large-up,
	thead.hide-for-medium,
	thead.hide-for-medium-down {
		display: table-header-group !important
	}

	tbody.show-for-large,
	tbody.show-for-large-up,
	tbody.hide-for-medium,
	tbody.hide-for-medium-down {
		display: table-row-group !important
	}

	tr.show-for-large,
	tr.show-for-large-up,
	tr.hide-for-medium,
	tr.hide-for-medium-down {
		display: table-row !important
	}

	td.show-for-large,
	td.show-for-large-up,
	td.hide-for-medium,
	td.hide-for-medium-down,
	th.show-for-large,
	th.show-for-large-up,
	th.hide-for-medium,
	th.hide-for-medium-down {
		display: table-cell !important
	}

	.row .large-offset-0,
	.row .large-offset-1,
	.row .large-offset-2,
	.row .large-offset-3,
	.row .large-offset-4,
	.row .large-offset-5,
	.row .large-offset-6,
	.row .large-offset-7,
	.row .large-offset-8,
	.row .large-offset-9,
	.row .large-offset-10,
	.push-1,
	.pull-1,
	.push-2,
	.pull-2,
	.push-3,
	.pull-3,
	.push-4,
	.pull-4,
	.push-5,
	.pull-5,
	.push-6,
	.pull-6,
	.push-7,
	.pull-7,
	.push-8,
	.pull-8,
	.push-9,
	.pull-9,
	.push-10,
	.pull-10,
	.push-11,
	.pull-11 {
		position: relative;
	}

	.push-1,
	.push-2,
	.push-3,
	.push-4,
	.push-5,
	.push-6,
	.push-7,
	.push-8,
	.push-9,
	.push-10,
	.push-11 {
		right: auto;
	}

	.pull-1,
	.pull-2,
	.pull-3,
	.pull-4,
	.pull-5,
	.pull-6,
	.pull-7,
	.pull-8,
	.pull-9,
	.pull-10,
	.pull-11 {
		left: auto;
	}

	.large-1 {
		width: 8.33333%;
	}

	.large-2 {
		width: 16.66667%;
	}

	.large-3 {
		width: 23%;
	}

	.large-4 {
		width: 33.33333%;
	}

	.large-5 {
		width: 41.66667%;
	}

	.large-6 {
		width: 50%;
	}

	.large-7 {
		width: 60.33333%;
	}

	.large-8 {
		width: 66.66667%;
	}

	.large-9 {
		width: 75%;
	}

	.large-10 {
		width: 83.33333%;
	}

	.large-11 {
		width: 91.66667%;
	}

	.large-12 {
		width: 100%;
	}

	.row .large-offset-0 {
		margin-left: 0;
	}

	.row .large-offset-1 {
		margin-left: 8.33333%;
	}

	.row .large-offset-2 {
		margin-left: 16.66667%;
	}

	.row .large-offset-3 {
		margin-left: 25%;
	}

	.row .large-offset-4 {
		margin-left: 33.33333%;
	}

	.row .large-offset-5 {
		margin-left: 41.66667%;
	}

	.row .large-offset-6 {
		margin-left: 50%;
	}

	.row .large-offset-7 {
		margin-left: 58.33333%;
	}

	.row .large-offset-8 {
		margin-left: 66.66667%;
	}

	.row .large-offset-9 {
		margin-left: 75%;
	}

	.row .large-offset-10 {
		margin-left: 83.33333%;
	}

	.row .large-offset-11 {
		margin-left: 91.66667%;
	}

	.push-1 {
		left: 8.33333%;
	}

	.pull-1 {
		right: 8.33333%;
	}

	.push-2 {
		left: 16.66667%;
	}

	.pull-2 {
		right: 16.66667%;
	}

	.push-3 {
		left: 25%;
	}

	.pull-3 {
		right: 25%;
	}

	.push-4 {
		left: 33.33333%;
	}

	.pull-4 {
		right: 33.33333%;
	}

	.push-5 {
		left: 41.66667%;
	}

	.pull-5 {
		right: 41.66667%;
	}

	.push-6 {
		left: 50%;
	}

	.pull-6 {
		right: 50%;
	}

	.push-7 {
		left: 58.33333%;
	}

	.pull-7 {
		right: 58.33333%;
	}

	.push-8 {
		left: 66.66667%;
	}

	.pull-8 {
		right: 66.66667%;
	}

	.push-9 {
		left: 75%;
	}

	.pull-9 {
		right: 75%;
	}

	.push-10 {
		left: 83.33333%;
	}

	.pull-10 {
		right: 83.33333%;
	}

	.push-11 {
		left: 91.66667%;
	}

	.pull-11 {
		right: 91.66667%;
	}

	.columns.large-centered {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		float: none !important;
	}

	.columns.large-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left !important;
	}

	.columns.large-uncentered.opposite {
		float: right !important
	}

	/* Remove medium grid clearing */
	.medium-block-grid-1>li:nth-of-type(1n+1),
	.medium-block-grid-2>li:nth-of-type(2n+1),
	.medium-block-grid-3>li:nth-of-type(3n+1),
	.medium-block-grid-4>li:nth-of-type(4n+1),
	.medium-block-grid-5>li:nth-of-type(5n+1),
	.medium-block-grid-6>li:nth-of-type(6n+1),
	.medium-block-grid-7>li:nth-of-type(7n+1),
	.medium-block-grid-8>li:nth-of-type(8n+1),
	.medium-block-grid-9>li:nth-of-type(9n+1),
	.medium-block-grid-10>li:nth-of-type(10n+1),
	.medium-block-grid-11>li:nth-of-type(11n+1),
	.medium-block-grid-12>li:nth-of-type(12n+1) {
		clear: none
	}

	.large-block-grid-1>li,
	.large-block-grid-2>li,
	.large-block-grid-3>li,
	.large-block-grid-4>li,
	.large-block-grid-5>li,
	.large-block-grid-6>li,
	.large-block-grid-7>li,
	.large-block-grid-8>li,
	.large-block-grid-9>li,
	.large-block-grid-10>li,
	.large-block-grid-11>li,
	.large-block-grid-12>li {
		padding: 0 $grid-gutter 2*$grid-gutter;
	}

	.large-block-grid-1>li:nth-of-type(n),
	.large-block-grid-2>li:nth-of-type(n),
	.large-block-grid-3>li:nth-of-type(n),
	.large-block-grid-4>li:nth-of-type(n),
	.large-block-grid-5>li:nth-of-type(n),
	.large-block-grid-6>li:nth-of-type(n),
	.large-block-grid-7>li:nth-of-type(n),
	.large-block-grid-8>li:nth-of-type(n),
	.large-block-grid-9>li:nth-of-type(n),
	.large-block-grid-10>li:nth-of-type(n),
	.large-block-grid-11>li:nth-of-type(n),
	.large-block-grid-12>li:nth-of-type(n) {
		clear: none
	}

	.large-block-grid-1>li:nth-of-type(1n+1),
	.large-block-grid-2>li:nth-of-type(2n+1),
	.large-block-grid-3>li:nth-of-type(3n+1),
	.large-block-grid-4>li:nth-of-type(4n+1),
	.large-block-grid-5>li:nth-of-type(5n+1),
	.large-block-grid-6>li:nth-of-type(6n+1),
	.large-block-grid-7>li:nth-of-type(7n+1),
	.large-block-grid-8>li:nth-of-type(8n+1),
	.large-block-grid-9>li:nth-of-type(9n+1),
	.large-block-grid-10>li:nth-of-type(10n+1),
	.large-block-grid-11>li:nth-of-type(11n+1),
	.large-block-grid-12>li:nth-of-type(12n+1) {
		clear: both
	}

	.large-block-grid-1>li {
		width: 100%;
	}

	.large-block-grid-2>li {
		width: 50%;
	}

	.large-block-grid-3>li {
		width: 33.33333%;
	}

	.large-block-grid-4>li {
		width: 25%;
	}

	.large-block-grid-5>li {
		width: 20%;
	}

	.large-block-grid-6>li {
		width: 16.66667%;
	}

	.large-block-grid-7>li {
		width: 14.28571%;
	}

	.large-block-grid-8>li {
		width: 12.5%;
	}

	.large-block-grid-9>li {
		width: 11.11111%;
	}

	.large-block-grid-10>li {
		width: 10%;
	}

	.large-block-grid-11>li {
		width: 9.09091%;
	}

	.large-block-grid-12>li {
		width: 8.33333%;
	}
}

/* << */


.fullSizeRow {
	max-width: none;
}

.lt-ie9 .fullSizeRow {
	width: 100%;
}

.fullSizeRow .wrapper {
	// max-width: $grid-width;
}

.fullSizeRow .massDataOuterShell,
.fullSizeRow .multipleColumnShell {
	@extend %columns;
	float: none;
}


/* Foundation Dropdowns */
.f-dropdown {
	border-radius: 4px;
	position: absolute;
	top: -9999px;
	list-style: none;
	margin-left: 0;
	width: 100%;
	max-height: none;
	height: auto;
	background: $color-athens-gray;
	font-size: 16px;
	z-index: 99;
	padding: 0;
	margin-top: 2px;
	max-width: 195px;
	box-shadow: 0 2px 0 darken($color-athens-gray, 10%);
}

.f-dropdown>*:first-child {
	margin-top: 0;
}

.f-dropdown>*:last-child {
	margin-bottom: 0;
}

.f-dropdown li {
	cursor: pointer;
	line-height: 1.125em;
	padding-top: emCalc(5px) !important;
	padding-bottom: emCalc(5px);
	margin: 0 emCalc(15px);
	color: $color-primary;
}

.f-dropdown li a {
	display: block;
	color: $color-primary;
}

.f-dropdown.content {
	position: absolute;
	top: -9999px;
	list-style: none;
	margin-left: 0;
	padding: 1.25em;
	width: 100%;
	height: auto;
	max-height: none;
	background: white;
	border: solid 1px #cccccc;
	font-size: 16px;
	z-index: 99;
	max-width: 200px;
}

.f-dropdown.content>*:first-child {
	margin-top: 0;
}

.f-dropdown.content>*:last-child {
	margin-bottom: 0;
}

.f-dropdown.tiny {
	max-width: 200px;
}

.f-dropdown.small {
	max-width: 300px;
}

.f-dropdown.medium {
	max-width: 500px;
}

.f-dropdown.large {
	max-width: 800px;
}

// .pageStyle_references [class*="column"] + [class*="column"]:last-child { float: left; }

/* Labels */
.label {
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	line-height: 22px;
	white-space: nowrap;
	display: inline-block;
	position: relative;
	padding: 0.4em 0.625em 0.4em;
	font-size: 1em;
	background-color: #2ba6cb;
	color: white;
}


/* Tooltips */
.has-tip {
	border-bottom: dotted 1px #cccccc;
	cursor: help;
	font-weight: bold;
	color: #333333;
}

.has-tip:hover,
.has-tip:focus {
	border-bottom: dotted 1px #196177;
	color: #2ba6cb;
}

.has-tip.tip-left,
.has-tip.tip-right {
	float: none !important;
}

.tooltip {
	display: none;
	position: absolute;
	z-index: 999;
	font-size: rem-calc(14px);
	line-height: 1.3;
	padding: rem-calc(8px) rem-calc(10px);
	max-width: 85%;
	left: 50%;
	width: 100%;
	color: white;
	background: rgba(70, 83, 91, 0.90);
	border-radius: 3px;
}

.tooltip>.nub {
	opacity: 0.9;
	display: block;
	left: 5px;
	position: absolute;
	width: 0;
	height: 0;
	border: solid 6px;
	border-color: transparent transparent #46535b transparent;
	top: -12px;
	pointer-events: none;
}

.tooltip.opened {
	color: #2ba6cb !important;
	border-bottom: dotted 1px #196177 !important;
}

.tap-to-close {
	display: block;
	font-size: 0.625em;
	color: #888888;
	font-weight: normal;
}

@media only screen and (min-width: 768px) {
	.tooltip>.nub {
		border-color: transparent transparent #46535b transparent;
		top: -12px;
	}

	.tooltip.tip-top>.nub {
		border-color: #46535b transparent transparent transparent;
		top: auto;
		bottom: -12px;
	}

	.tooltip.tip-left,
	.tooltip.tip-right {
		float: none !important;
	}

	.tooltip.tip-left>.nub {
		border-color: transparent transparent transparent #46535b;
		right: -10px;
		left: auto;
		top: 50%;
		margin-top: -5px;
	}

	.tooltip.tip-right>.nub {
		border-color: transparent #46535b transparent transparent;
		right: auto;
		left: -10px;
		top: 50%;
		margin-top: -5px;
	}
}

@media only screen and (max-width: 767px) {
	.f-dropdown {
		max-width: 100%;
		left: 0;
	}
}