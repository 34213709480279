/* ============================================================================
   Component: Sidemenu
   ========================================================================= */

.sidemenu {
	margin-top: 0;

	&-outer {
		margin: 0;

		.sidemenu-header {
			position: relative;
			font-family: $base-font-family;
			font-size: 1.1rem;
			font-weight: 800;
			color: $color-dove-gray;
			border-bottom: 2px solid #ccc;
			margin: 0 0 rem-calc(15px);

			.edit-icon {
				position: absolute;
				right: 0;
				top: 2px;
				font-size: .9rem;
				cursor: pointer;

				&:hover {
					color: $color-dark;
				}
			}
		}
	}
}