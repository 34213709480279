/* ============================================================================
   Page: Password Recovery Page
   ========================================================================= */

.pageStyle_pwdrecovery {
	@extend %background-light;

	.contentElements {
		padding-top: 10%;
	}

	.headline1 {
		@extend .headline-account;
		padding-top: 0;
	}

	#head,
	#footer {
		display: none;
	}
}