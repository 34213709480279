/* ============================================================================
   Base: Layout Main
   ========================================================================= */

$body-background: $color-athens-gray !default;
$header-background: $color-light !default;
$content-padding: 0 !default;
$content-background: transparent !default;

/**
 * Head Variables
 */
$head-height: rem-calc(74px) !default;
$head-background: $color-light !default;
$head-border-bottom: 1px solid $color-mercury !default;
$head-responsive-height: rem-calc(54px) !default;


/* ********************************************************************** */
/* Caution!															 */
/* Do not change the code block headlines especially the version number */
/* at the end of the code block headline.							   */
/* These are essential information for update purposes.				 */
/*																	  */
/* Template: simple													 */
/* ********************************************************************** */

/* >> Angaben nicht veraendern!!! Version: rel-1-0-1 */
.block {
	display: block;
	height: 0;
}

.clearAll {
	display: block;
	clear: both;
	visibility: hidden;
	height: 0;
}

.floatTerm {
	clear: both;
}

/* Standard viewport definition */
.viewport {
	position: relative;
}

.el_hide,
.co_hide,
.hide {
	display: none;
}

.fixed {
	position: fixed;
}

/* << */


/* >> Standard body Definitionen Version: rel-1-0-1 */
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

/* Anpassungen fuer die Standardeinstellungen des Bodys */
body {
	background: $body-background;
	font-family: $base-font-family;
	font-weight: $regular;
	font-size: 100%;
	line-height: emCalc(24px);
	color: $color-dark;
	-webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

@-ms-viewport {
	width: device-width;
}

/* Grundeinstellungen fuer einige Element festlegen */
th,
td,
div,
p {}

/* Anpassungen fuer die Standard Absaetze */
p,
ul,
ol {
	margin-top: 0;
}

/* Zeilenhoehe im Contentbereich */
.content p,
.content ul,
.content div {}

.regular {
	font-weight: $regular;
}

.semi-bold {
	font-weight: $semi-bold;
}

.subtitle,
.teaser .title,
.paraSubtitle,
.h2,
.h4 {
	color: $color-grey;
}

h2,
h4,
h5,
b,
strong,
.title,
.paraTitle,
.paraSubtitle,
.h2,
.h4,
.h5 {
	font-weight: $semi-bold;
}

h1,
.h1 {
	font-size: emCalc(36px);
	line-height: emCalc(54px, 46px);
	font-weight: $color-headline-weight;
	margin: 0 0 0.2em 0;
}

.contentStyle_woTeasers h1,
.contentStyle_woTeasers .h1 {
	text-align: center;
}

h2,
.h2,
.title,
h3,
.h3 {
	font-size: emCalc(30px);
	line-height: emCalc(30px, 22px);
	font-weight: $color-headline-weight;
	margin: 0 0 0.2em 0;
}

.contentStyle_woTeasers h2,
.contentStyle_woTeasers .h2 {
	text-align: center;
}

.subtitle,
.paraSubtitle {
	font-size: emCalc(20px);
	line-height: emCalc(24px, 20px);
	margin: 0 0 0.2em 0;
}

h4 {
	margin: 0 0 0.2em 0;
}

a,
a:hover {
	color: $color-link-color;
	text-decoration: none;
	outline: none;
}

/* Standard Link Einstellungen */
h1,
h2,
h3,
.title,
.paraTitle,
.h1,
.h3 {
	color: $color-headline-color;
}

a:hover {
	color: $color-link-hover-color;
	text-decoration: underline;
}

form,
nav ul,
.lt-ie9 nav ul {
	margin: 0;
	padding: 0;
}

input,
select,
textarea {
	font-family: $base-font-family, sans-serif;
	font-size: emCalc(18px);
}

input:focus::placeholder {
	color: transparent;
}

textarea:focus::placeholder {
	color: transparent;
}

input.button {
	border: 0 none;
	cursor: pointer;
	cursor: hand;
}

a img {
	border: 0 none;
}

/* Standardangaben fuer Navigationen */
nav ul {
	clear: both;
	@extend .clearfix;
}

hr {
	background-color: $color-border-grey;
	height: 1px;
	margin: 2em 0;
	border: 0 none;
}

img,
object,
embed {
	max-width: 100%;
	height: auto;
}

object,
embed {
	height: 100%
}

img {
	display: inline-block;
	vertical-align: middle;
	*behavior: none;
}

textarea {
	height: auto;
	min-height: 9em;
}

select {
	width: 100%
}

.small {
	font-size: 75%;
	line-height: 75%;
}

em {
	font-weight: $semi-bold;
}

/* << */


/* >> Multi used styles */
/* clearfix */
.clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfixBefore {
	&:before {
		content: "";
		display: table;
		clear: both;
	}
}

/* Background images for multiple usage */
%icon {
	vertical-align: top;
	background-image: $icon-url;
	background-position: -3000px -3000px;
	background-repeat: no-repeat;
}

.icon {
	@include icon;
}

.disabled-elm {
	display: none;
}

.gt-ie8 div,
.gt-ie8 a,
.gt-ie8 img,
.gt-ie8 p,
.gt-ie8 ul,
.gt-ie8 span {
	filter: none !important;
	-ms-filter: none !important;
}

.aCShell,
.contentTeaserBottomContainer {
	// max-width: $grid-width;
	margin: $grid-alignment;
	overflow: hidden;
}

.contentStyle_woTeasers .aCShell,
.contentStyle_woTeasers .contentTeaserBottomContainer,
.contentStyle_woTeasers .headline1,
.contentStyle_woTeasers .headline2,
.contentStyle_woTeasers .paragraphOuter {
	padding-left: $grid-gutter-pixel;
	padding-right: $grid-gutter-pixel;
}

.contentStyle_woTeasers .columns .headline1,
.contentStyle_woTeasers .columns .headline2,
.contentStyle_woTeasers .columns .paragraphOuter {
	padding-left: 0;
	padding-right: 0;
}

@media only screen and (min-width: $break-medium) {
	.valign-middle {
		display: table;
		table-layout: fixed;
		width: 100% !important;

		>.columns {
			display: table-cell;
			vertical-align: middle;
		}

		>.columns,
		>[class*="column"]+[class*="column"]:last-child {
			float: none !important;
		}
	}
}

/* << */


/* >> Standard Seiteneinstellungen Version: rel-1-0-0 */
/* outer page wrapper */
#wrapper {
	position: relative;
	z-index: 1;
	min-height: 100%;
}

/* head elements outer box */
#head {
	min-height: $head-height;
	background: $head-background;
	border-bottom: $head-border-bottom;

	@media only screen and (max-width: $break-medium) {
		min-height: $head-responsive-height;
	}
}

#sidebar-left,
#sidebar-right {
	width: 23.5%;
	margin-top: 3px;
}

/* left sidebar/teaser box */
#sidebar-left {
	float: left;
}

/* right sidebar/teaser box */
#sidebar-right {
	float: right;
}

/* content box */
#content {
	width: 51%;
	float: left;
	margin-left: 24.5%;
	margin-right: -200%;
}

.contentStyle_leftTeaser #content,
.contentStyle_rightTeaser #content {
	width: 74%;
}

/* without right sidebar/teaser */
.contentStyle_leftTeaser #content {}

.contentStyle_leftTeaser #sidebar-left {}

/* without left sidebar/teaser */
.contentStyle_rightTeaser #content {
	margin-left: 0;
}

/* without both sidebars/teasers */
.contentStyle_woTeasers #content {
	width: 100%;
	float: none;
	margin: 0;
}

/* footer box */
#footer {
	clear: both;
}

.wrapper .wrapper,
.contentStyle_woTeasers .contentElements .wrapper,
#footer .wrapper {
	padding-left: 0;
	padding-right: 0;
	max-width: none;
}

.wrapper,
.globalLayerShell,
.flOuterShell_floats .nav,
.contentStyle_woTeasers .contentElements .wrapper .wrapper,
#footer .wrapper .wrapper,
.massDataSearchWoResEl,
.massDataWarningShell {
	width: 100%;
	margin: $grid-alignment;
	// max-width: $grid-width;
	padding-left: $grid-gutter-pixel;
	padding-right: $grid-gutter-pixel;
}

/* element wrapper (TopNav, firstLevelNav, header,...) */
.wrapper {
	@extend .clearfix;
}

/* Schaltet den Skip Link aus (Wird bei Barriere freien Seiten verwendet) */
.skipNav {
	display: none;
}

/* Globale Ebenen Shell */
.globalLayerShell {
	width: 100%;
	position: absolute;
	left: auto;
	top: auto;
	z-index: 1010;
}

.globalLayerShell .wrapper {
	position: relative;
}

/* Breite der Kopfelemente */
.headerElements {
	*zoom: 1;
	background: $header-background;
}

/* Einstellungen fuer Inhaltelemente */
.contentElements {
	padding: $content-padding;
	background: $content-background;
}

/* innerer Container fuer den Inhalt */
.contentInner {
	position: relative;
}

.contentStyle_woTeasers .contentInner {}

/* << */


/* >> Zusaetzliche Steuerelemente (Zurueck, Drucken, Empfehlung versenden) Version: rel-1-0-0 */
/* Allgemeiner Container */
.aCShell {
	@extend .disabled-elm;
}

/* Container oben */
.topAddContrContainer {}

/* Container unten */
.bottomAddContrContainer {}

/* Einzelelemente */
.aCShell .item {
	display: none;
	float: left;
	margin: 0 0 1em 0;
}

/* Text */
.aCShell .pmt {
	font-size: emCalc(14px);
}

.aCShell .backButton {
	display: block;
}

.lt-ie9 .backButton {
	width: 90%;
}

/* Allgemeine Icon Einstellungen */
.aCShell .icon {}

/* Zurueck */
.backButton .icon {
	display: none;
}

/* Drucken */
.printButton .icon {}

/* Empfehlen */
.recomButton .icon {}

/* Barrierefreie Buttons */
.printButton,
.recomButton,
.bFreeButton,
.cBlindButton,
.cBlindResetButton {
	display: none;
}

/* << */


/* >> Freie Inhaltscont Version: rel-1-0-0 */
/* Standard Definition fuer die freien Inhaltscontainer. Diese Angabe bitte unveraendert lassen */
.stdSty {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}

/* Hier koennen die angelegten Container definiert werden */
.banner {}

/* Container im Inhaltsbereich */
.contentTeaserTopContainer,
.contentTeaserBottomContainer {}

.contentTeaserTopContainer p,
.contentTeaserBottomContainer p {}

/* Standard Definition fuer die freien Inhaltscontainer am Seitenende. Diese Angabe bitte unveraendert lassen */
.pageBottomContainer,
.pageTopContainer {
	display: none;
}

/* <<  */


/* >> Fusszeile Version: rel-1-0-0 */
/* Anpassungen fuer den Fusszeilenkontainer */
.footerInner {}

/* Anpassungen der Fusszeilen Inhaltselemente */
.footer p,
.footer ul,
.footer ol,
.footer form,
.footer h1,
.footer h2,
.footer h3,
.footer h4,
.footer h5,
.footer h6 {}

/* <<  */


/* >> Allgemeine Absatz und Panel Definitionen Version: rel-1-0-0 */
/* Absatz Titel */
.paraTitle {}

/* Absatz Titel im Footerbereich */
.footer .paraTitle {}

/* Definition Absatz Untertitel */
.paraSubtitle {}

/* Definition Absatz Untertitel im Footerbereich */
.footer .paraSubtitle {}

/* Einstellungen der Bild und Content ausrichtung */
.paraImg,
.content .image,
.footer .image {
	margin-bottom: emCalc(10px);
}

.columns .paraImg,
.columns .image {
	margin-bottom: 0;
}

/* Fuer Bild (mit Bildtext) zu Textabstand bei links und rechts umfliessend */
.paraFloatImgOuterL {
	padding-right: emCalc(20px);
	float: left;
}

.paraFloatImgOuterR {
	padding-left: emCalc(20px);
	float: right;
}

/* Fuer Bild (ohne Bildtext) zu Textabstand bei links und rechts umfliessend */
.content .image_left_float,
.footer .image_left_float {
	margin-right: emCalc(20px);
	float: left;
}

.content .image_right_float,
.footer .image_right_float {
	margin-left: emCalc(20px);
	float: right;
}

.paraFloatImgOuterL .image,
.paraFloatImgOuterR .image {
	float: none;
}

/* Bild zu Textabstand bei rechts und links freibleibend */
.paraFxContOuterL {
	padding-right: emCalc(20px);
}

.paraFxContOuterR {
	padding-left: emCalc(20px);
}

.paraImg .image {
	padding: 0;
	margin: 0;
}

.paraFloatImgOuterL .image,
.paraFloatImgOuterR .image,
.paraFxImgOuterL .image,
.paraFxImgOuterR .image {
	display: block;
}

/* Bild zu Text im zentrierten Modus */
.paraCeImgOuter,
.paraCeTopImgOuter {
	width: 100%;
	padding: 0;
	margin: 0;
	text-align: center;
	zoom: 1;
}

/* Bild Untertext */
.content .sT {
	font-size: emCalc(12px);
	line-height: emCalc(16px);
	text-align: left;
	padding: emCalc(8px, 12px) 0 0 0;
	margin: 0 auto;
}

.paraFloatImgOuterL .sT,
.paraFxImgOuterL .sT,
.paraFloatImgOuterR .sT,
.paraFxImgOuterR .sT {
	float: left;
}

.paraCeImgOuter .sT {
	text-align: center;
}

/* Einstellungen fuer den Absatz  "mehr..." Link */
.paraSublinkShell {
	display: block;
	clear: both;
	text-align: left;
	margin-top: emCalc(3px);
}

.paraSublinkShell:before {
	font-size: 14px;
	margin: emCalc(6px, 14px) emCalc(5px, 14px) 0 0;
	@extend .font-icon:before;
	@extend .font-icon-right:before !optional;
}

/* Einstellungen fuer den Absatz  "mehr..." Link im Footerbereich */
.footer .paraSublinkShell {}

/* Absatz Sublink */
.paraSublinkShell .sL {}

/* Absatz Sublink Footerbereich */
.footer .paraSublinkShell .sL {}

/* Einstellungen der Absatz und Panel "mehr.." Link Icons */
.paraSublinkShell .icon {
	display: none;
}

/* Einstellungen der Absatz und Panel "mehr.." Link Icons im Footerbereich */
.footer .paraSublinkShell .icon {
	display: none;
}

/* << */


/* >> Blaetterelement Version: rel-1-1-0 */
.pagerExtendedShell {
	margin: 1em 0;
}

.pager_top .pagerExtendedShell {
	margin-top: 0;
}

.pager_bottom .pagerExtendedShell {
	margin-bottom: 0;
}

.pagerExtDes1 {}

.pagerExtendedShell div.results,
.pagerExtendedShell .itemsPerPage,
.pagerExtendedShell .pInfo,
.pagerExtendedShell .goToPageFormShell,
.pagerExtendedShell .pagerSearchShell {
	display: none;
}

.pagerExtendedShell .pPages {
	text-align: center;
}

/* Pager Kontainer */
.pager {}

/* Definition des Pagers */
.pPages .pagerList {
	display: inline-block;
	list-style: none outside none;
	clear: both;
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

/* Einstellungen der einzelnen Eintraege */
.pPages li {
	float: left;
	line-height: rem-calc(32px);
	font-size: rem-calc(22px);
	text-align: center;
}

.pPages .normal,
.pPages .here,
.pPages .icon,
.pPages .active,
.pPages .passive {
	vertical-align: top;
}

.pPages a,
.pPages .here,
.pPages .passive {
	width: rem-calc(30px);
	display: block;
	text-decoration: none;
	border-radius: 4px;
}

.pPages .passive {
	opacity: 0.3;
}

/* Einstellung der momentan angewaehlten Seite */
.pPages .here {
	color: $color-light;
	background: $color-primary;
}

/* Anpassen der Links */
.pPages a:link,
.pPages a:visited,
.pPages a:hover {
	color: $color-grey;
	@include single-transition(all);
}

.pPages a.normal:hover {
	color: $color-light;
	background: $color-primary;
}

/* Pager Icons */
.pPages .icon {
	width: $icons-sprite-we-width;
	height: $icons-sprite-we-height;
	margin: rem-calc(14px) 0 0 0;
}

.pPages .prevAct,
.pPages .prevPass,
.pPages .nextAct,
.pPages .nextPass {}

.pPages .pagerList .item_icon:first-child,
.pPages .pagerList .item_icon:last-child,
.pPages .firstAct,
.pPages .firstPass,
.pPages .lastAct,
.pPages .lastPass {}

.pPages .pagerList .item_icon:first-child,
.pPages .pagerList .item_icon:last-child {
	margin: 0 rem-calc(10px);
}

.pPages .firstAct,
.pPages .firstPass,
.pPages .lastAct,
.pPages .lastPass {
	width: $icons-sprite-we-end-width;
}

/*  Anpassungen fuer den linken Pager Pfeil aktiv und passiv */
.pPages .firstAct,
.pPages .firstPass {
	background-position: $icons-sprite-w-end-blue-pos;
}

.pPages .firstPass {}

/* Anpassungen fuer den halb linken Pager Pfeil aktiv und passiv */
.pPages .prevAct,
.pPages .prevPass {
	background-position: $icons-sprite-w-blue-pos;
}

.pPages .prevPass {}

/* Anpassungen fuer den halb rechten Pager Pfeil aktiv und passiv */
.pPages .nextAct,
.pPages .nextPass {
	background-position: $icons-sprite-e-blue-pos;
}

.pPages .nextPass {}

/* Anpassungen fuer den rechten Pager Pfeil aktiv und passiv */
.pPages .lastAct,
.pPages .lastPass {
	background-position: $icons-sprite-e-end-blue-pos;
}

.pPages .lastPass {}

/* Anpassungen fuer das Pager Trennelement */
.pPages .sep .icon {
	width: rem-calc(7px);
	background: none transparent;
}

.pPages .sepOuter .icon {
	width: rem-calc(23px);
	background: none transparent;
}

/* << */

/* >> Blaetterelement Version: rel-1-99-0 */
.pager {
	padding-top: 15px;

	&__results,
	&__info,
	&__form {
		display: none;
	}

	&__pages {
		@extend .clearfix;
		text-align: center;

		&-list {
			list-style: none;
			padding: 0;
			display: inline-block;
			margin-bottom: 1em;

			.item {
				display: inline-block;
				padding-left: 5px;
				padding-right: 5px;
				line-height: rem-calc(32px);
				font-size: rem-calc(22px);

				&--sep {
					width: rem-calc(7px);
					background: none transparent;
					//height: rem-calc(32px);
					padding: 0;

					&.sepOuter {
						border: none;
					}
				}

				a,
				.here,
				.passive {
					width: rem-calc(30px);
					display: block;
					text-decoration: none;
					border-radius: 4px;
				}

				/* Einstellung der momentan angewaehlten Seite */
				.here {
					color: $color-light;
					background: $color-primary;
				}

				/* Anpassen der Links */
				a:link,
				a:visited,
				a:hover {
					color: $color-grey;
					@include single-transition(all);
				}

				a.normal:hover {
					color: $color-light;
					background: $color-primary;
				}
			}
		}
	}

	&__nav-before,
	&__nav-after {

		.item {
			padding-left: 5px;
			padding-right: 5px;
		}

		.item>* {
			@include icon;
			width: $icons-sprite-we-width;
			height: $icons-sprite-we-height;
			margin: rem-calc(14px) 0 0 0;
			display: inline-block;
		}

		.item--first>*,
		.item--last>* {
			width: $icons-sprite-we-end-width;
		}

		.passive {
			opacity: 0.3;
		}
	}

	&__nav-before {
		display: inline-block;
		vertical-align: top;
		padding-right: 15px;

		.item--first>* {
			background-position: $icons-sprite-w-end-blue-pos;
			margin: rem-calc(11px) 0 0 0;
		}

		.item--prev>* {
			background-position: $icons-sprite-w-blue-pos;
			margin: rem-calc(11px) 0 0 0;
		}
	}

	&__nav-after {
		display: inline-block;
		vertical-align: top;
		padding-left: 15px;

		.item--last>* {
			background-position: $icons-sprite-e-end-blue-pos;
			margin: rem-calc(11px) 0 0 0;
		}

		.item--next>* {
			background-position: $icons-sprite-e-blue-pos;
			margin: rem-calc(11px) 0 0 0;
		}
	}

	&--simple {
		padding-bottom: 1em;
	}

	&--no-pages {

		.pager__pages {
			padding-bottom: 1em;

			.pager__pages-info {
				display: inline-block;
			}
		}
	}
}

/* << */


/* >> Trenner fuer Content Elemente Version: rel-1-0-0 */
/* Trennelement Container */
.separatorShell {
	line-height: 0;
	padding: 1em 0 emCalc(1px);
	clear: both;
}

/* Trennelement Abstandshalter */
.separatorShell img {
	width: 100%;
	height: 2px;
	background: $color-border-grey;
}

/* << */


/* >> Seitentitelelement Version: rel-1-0-0 */
/*  Aeussere Elemethuelle bei Ueberschriften mit Bild */
.titlesShell {
	@extend .clearfix;
}

/* Bild zu Textabstand */
.titlesShell .titleContL {
	padding-right: $grid-gutter-pixel;
}

.titlesShell .titleContR {
	padding-left: $grid-gutter-pixel;
}

.headline1 {
	padding-top: emCalc(34px, 36px);
	padding-bottom: emCalc(8px, 36px);
}

.headline2 {
	padding-top: emCalc(7px);
	margin-bottom: emCalc(3px, 30px);
}

/* << */


/* >> multiple column element Version: rel-1-0-1 */
/* Mehrspalten Titel aussen */
.multipleColTitleOuter,
.massDataOuterTitle {
	display: block;
	font-size: emCalc(36px);
	line-height: emCalc(49px, 36px);
	font-weight: $extra-light;
	margin-bottom: emCalc(7px, 36px);
}

/* Mehrspalten Tabelle */
.multipleColumnShell,
.massDataOuterShell {
	@extend .clearfix;
}

/* Mehrspalten Zeilen Einstellungen */
.multipleColumnShell .row,
.massDataOuterShell .row {}

.multipleColumnShell .odd,
.multipleColumnShell .even,
.multipleColumnShell .firstRow,
.massDataOuterShell .odd,
.massDataOuterShell .even,
.massDataOuterShell .firstRow {}

/* Use selector col_1, col_2, ... to modify specific columns */
.multipleColumnShell .col,
.massDataOuterShell .col {}

.multipleColumnShell .lastCol,
.massDataOuterShell .lastCol {}

/* Flexible with javascript */
.multipleCol_flexible .col,
.massDataCol_flexible .col {}

.multipleCol_flexible .lastCol,
.massDataCol_flexible .lastCol {}

.multiCol_1 .col,
.massDataCol_1 .col {
	width: 100%;
}

.multiCol_2 .col,
.massDataCol_2 .col {
	width: 50%;
}

.multiCol_3 .col,
.massDataCol_3 .col {
	width: 33.33333%;
}

.multiCol_4 .col,
.massDataCol_4 .col {
	width: 25%;
}

.multiCol_5 .col,
.massDataCol_5 .col {
	width: 20%;
}

.multiCol_6 .col,
.massDataCol_6 .col {
	width: 16.66667%;
}

.multiCol_7 .col,
.massDataCol_7 .col {
	width: 14.2856%;
}

.multiCol_8 .col,
.massDataCol_8 .col {
	width: 12.5%;
}

.multiCol_9 .col,
.massDataCol_9 .col {
	width: 11.1111%;
}

.multiCol_10 .col,
.massDataCol_10 .col {
	width: 10%;
}

/* << */


/* >> Anpassungen fuer Positionierungsinfo-Element Version: rel-1-0-0 */
/* Anpassung fuer linke/rechte Spalte */
.twoColElShell {
	width: 100%;
	@extend .clearfix;
}

.twoColElShell .l,
.twoColElShell .m,
.twoColElShell .r {
	float: left;
}

.twoColElShell .l,
.twoColElShell .r {
	width: 48%;
}

.twoColElShell .m {
	width: 4%;
}

/* Anpassungen fuer halbe Breite */
.halfWidthElShell {
	text-align: center;
}

.halfWidthElShell .halfWidthCol {
	width: 50%;
	margin: 0 auto;
	text-align: left;
}

/* << */


/* >> jquery ui core Version: (Angaben nicht veraendern!!!) rel-1-0-0 */
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	position: absolute;
	left: -99999999px;
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

.ui-helper-clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.ui-helper-clearfix {
	display: inline-block;
}

* html .ui-helper-clearfix {
	height: 1%;
}

.ui-helper-clearfix {
	display: block;
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}

.ui-state-disabled {
	cursor: default !important;
}

.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}

.ui-widget-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* positioning */
.ui-icon-carat-1-n {
	background-position: 0 0;
}

.ui-icon-carat-1-ne {
	background-position: -16px 0;
}

.ui-icon-carat-1-e {
	background-position: -32px 0;
}

.ui-icon-carat-1-se {
	background-position: -48px 0;
}

.ui-icon-carat-1-s {
	background-position: -64px 0;
}

.ui-icon-carat-1-sw {
	background-position: -80px 0;
}

.ui-icon-carat-1-w {
	background-position: -96px 0;
}

.ui-icon-carat-1-nw {
	background-position: -112px 0;
}

.ui-icon-carat-2-n-s {
	background-position: -128px 0;
}

.ui-icon-carat-2-e-w {
	background-position: -144px 0;
}

.ui-icon-triangle-1-n {
	background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
	background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
	background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
	background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
	background-position: -64px -16px;
}

.ui-icon-triangle-1-sw {
	background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
	background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
	background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
	background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
	background-position: -144px -16px;
}

/* << */


/* >> javascript slider Version: rel-1-0-1 */
.jsSliderShell,
.sliderCont,
.jsSliderItem {
	@extend .disabled-elm;
}

/* << */


/*>> Mass data warning element Version: rel-1-0-0 */
.massDataWarningShell {
	margin-top: $grid-top-bottom;
	margin-bottom: $grid-top-bottom;
}

.massDataWarningShell div.wdes1 .massDataWarningShell div.wdes2,
.massDataWarningShell div.wdes3,
{}

.massDataWarningShell div.wdes1 {
	@include alertBox_red;
}

/* << */


/* >> Mass data Search Version: rel-1-0-0 */
.massDataSearchShell {}

.massDataSearchShell .des1 {}

/* title and additional links */
.massDataSearchShell .baseNav {
	@extend .disabled-elm;
}

.massDataSearchShell .baseNav td {
	white-space: nowrap;
}

.massDataSearchShell .baseNav .title {
	width: 100%;
}

.massDataSearchShell .baseNav .res,
.massDataSearchShell .baseNav .ext {
	padding-left: 1em;
}

.massDataSearchShell .baseNav .res a,
.massDataSearchShell .baseNav .ext a {}

.massDataSearchWoResEl {
	margin-top: $grid-top-bottom;
	margin-bottom: $grid-top-bottom;
}

.massDataSearchWoResEl .sworDes1,
.massDataSearchWoResEl .sworDes2,
.massDataSearchWoResEl .sworDes3,
.massDataSearchWoResEl .sworDes4 {}

.massDataSearchWoResEl .sworDes1 {
	@include alertBox_grey;
}

/* form elements */
.massDataElsShell {
	margin: 0;
}

.massDataElsShell .elsMain {
	width: 100%;
}

.massDataElsShell .elsMain .fields {
	width: 100%;
}

.massDataElsShell .elsMain .fields td {
	vertical-align: top;
}

.massDataElsShell .elsMain .go {
	display: none;
	padding-bottom: emCalc(10px);
	vertical-align: bottom;
}

.massDataElsShell .elsMain .button {}

.massDataElsShell .els {
	width: 100%;
}

.massDataElsShell .field {
	padding-right: 2 * $grid-gutter-pixel;
}

.massDataElsShell .field div.prompt {
	font-size: emCalc(20px);
	color: $color-grey;
	padding-bottom: emCalc(16px, 20px);
}

.massDataElsShell .field input,
.massDataElsShell .field select {}

/* << */


/* >> Mass data items Version: rel-1-0-1 */
.massDataOuterShell {}

.mdOverviewShell,
.mdDetailShell {}

.mdDetailShell {}

.massDataInnerShell {}

.massDataOuterTitle {}

.massDataOuterDesc {}

/* mass data rows */
.massDataOuterShell .row {}

.massDataOuterShell .odd,
.massDataOuterShell .even,
.massDataOuterShell .firstRow {}

/* Use selector col_1, col_2, ... to modify specific columns */
.massDataOuterShell .col {}

.massDataOuterShell .lastCol {}

/* Flexible with javascript */
.massDataCol_flexible .lastCol {}

/* missing product panel (colnumber > panels) */
.massDataOuterShell .dummyProd {}

/* sublink */
.massSublinkShell {
	clear: both;
}

.massSublinkShell .icon {
	display: none;
}

/* << */


/*>> Mass data error list element Version: rel-1-0-0 */
.massDataErrorList {
	@include alertBox_red;
	padding-left: 2.7em;
}

.massDataErrorList li {}

.massDataErrorList li:before {
	color: $color-redbox-icon;
	text-shadow: -1px 0 $color-redbox, 0 1px $color-redbox, 1px 0 $color-redbox, 0 -1px $color-redbox;
}

/* << */


/* >> News Portal Elements Version: rel-1-0-0 */
.newsParagraph {
	@extend .disabled-elm;
}

/* << */


/* >> xpopup Version: rel-1-1-0 */
div.xpopup,
div.xpopup_fixed,
div.xpopup_slideshow,
div.xpopup_slideshow_fixed {
	display: inline;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 500001;
	border: 1px solid #000;
	background: #fff;
}

div.xpopup_fixed,
div.xpopup_slideshow_fixed {
	position: fixed;
}

div.xpopup_slideshow,
div.xpopup_slideshow_fixed {
	background: transparent;
	border: 1px solid transparent;
}

#xpopupCloseButton div.inner {
	padding: 4px 7px;
	text-align: right;
}

/* Background-Images */
#xpopupHoverMenu,
#xpopupHoverMenu .playButton_pass,
#xpopupHoverMenu .playButton_act,
#xpopupHoverMenu .pauseButton_pass,
#xpopupHoverMenu .pauseButton_act,
#xpopupHoverMenu .previousButton_pass,
#xpopupHoverMenu .previousButton_act,
#xpopupHoverMenu .nextButton_pass,
#xpopupHoverMenu .nextButton_act,
#xpopupHoverMenu .thumbsButton_pass,
#xpopupHoverMenu .thumbsButton_act,
#xpopupHoverMenu .closeButton_pass,
#xpopupHoverMenu .closeButton_act,
#xpopupGalleryOverview a.inner1:link,
#xpopupGalleryOverview a.inner1:visited,
#xpopupGalleryOverview a.inner1:hover,
#xpopupCloseButton div.inner img {
	// background: url(img/xpopup_all.png) 0 0 no-repeat;
}

#xpopupCloseButton div.inner img {
	width: 17px;
	height: 17px;
	background-position: -300px -100px;
	cursor: hand;
	cursor: pointer;
}

div.xpopup_slideshow #xpopupCloseButton,
div.xpopup_slideshow_fixed #xpopupCloseButton {
	visibility: hidden;
}

#xpopupContent {
	/* do not change this properties if you dont now what to do at javascript */
	padding: 0 7px 7px;
}

#xpopupAddOns {}

div.xpopupImgTitle,
div.xpopupImgTitle_slideshow {
	font-size: 11px;
	color: #666;
	padding: 0 10px 15px;
}

div.xpopupImgTitle_slideshow {
	color: #ccc;
}

#xpopupHoverMenuOuter {
	display: none;
}

#xpopupHoverMenuPos {
	position: relative;
	z-index: 10;
}

#xpopupHoverMenu {
	position: absolute;
	top: -70px;
	z-index: 20;
	height: 52px;
	width: 265px;
	background-position: 0 -100px;
}

#xpopupHoverMenuPlayPauseButton,
#xpopupHoverMenuPreviousButton,
#xpopupHoverMenuNextButton,
#xpopupHoverMenuThumbsButton,
#xpopupHoverMenuCloseButton {
	position: absolute;
	z-index: 10;
}

#xpopupHoverMenuPlayPauseButton {
	left: 20px;
	top: 16px;
	width: 20px;
	height: 22px;
}

#xpopupHoverMenuPreviousButton {
	left: 63px;
	top: 16px;
	width: 31px;
	height: 22px;
}

#xpopupHoverMenuNextButton {
	left: 109px;
	top: 16px;
	width: 31px;
	height: 22px;
}

#xpopupHoverMenuThumbsButton {
	left: 165px;
	top: 14px;
	width: 30px;
	height: 25px;
}

#xpopupHoverMenuCloseButton {
	left: 223px;
	top: 16px;
	width: 22px;
	height: 22px;
}

#xpopupHoverMenu .playButton_pass,
#xpopupHoverMenu .pauseButton_pass,
#xpopupHoverMenu .previousButton_pass,
#xpopupHoverMenu .nextButton_pass,
#xpopupHoverMenu .thumbsButton_pass,
#xpopupHoverMenu .closeButton_pass {
	-moz-opacity: 0.5;
	opacity: 0.5;
	filter: alpha(opacity=50);
}

#xpopupHoverMenu .playButton_act,
#xpopupHoverMenu .pauseButton_act,
#xpopupHoverMenu .previousButton_act,
#xpopupHoverMenu .nextButton_act,
#xpopupHoverMenu .thumbsButton_act,
#xpopupHoverMenu .closeButton_act {
	-moz-opacity: 1;
	opacity: 1;
	filter: alpha(opacity=100);
	cursor: hand;
	cursor: pointer;
}

#xpopupHoverMenu .playButton_pass {
	background-position: -100px -50px;
}

#xpopupHoverMenu .playButton_act {
	background-position: -100px 0;
}

#xpopupHoverMenu .pauseButton_pass {
	background-position: -150px -50px;
}

#xpopupHoverMenu .pauseButton_act {
	background-position: -150px 0;
}

#xpopupHoverMenu .previousButton_pass {
	background-position: -50px -50px;
}

#xpopupHoverMenu .previousButton_act {
	background-position: -50px 0;
}

#xpopupHoverMenu .nextButton_pass {
	background-position: -200px -50px;
}

#xpopupHoverMenu .nextButton_act {
	background-position: -200px 0;
}

#xpopupHoverMenu .thumbsButton_pass {
	background-position: 0 -50px;
}

#xpopupHoverMenu .thumbsButton_act {
	background-position: 0 0;
}

#xpopupHoverMenu .closeButton_pass {
	background-position: -250px -50px;
}

#xpopupHoverMenu .closeButton_act {
	background-position: -250px 0;
}

/* gallery */
#xpopupGalleryOverview {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 500002;
	overflow: auto;
}

#xpopupGalleryOverview div.xpopupGalleryImageDeco {
	float: left;
	padding: 15px;
}

#xpopupGalleryOverview a.inner1:link,
#xpopupGalleryOverview a.inner1:visited,
#xpopupGalleryOverview a.inner1:hover {
	display: block;
	width: 175px;
	height: 150px;
	background-position: -200px -200px;
	overflow: auto;
	text-decoration: none;
	border: none;
}

#xpopupGalleryOverview a.inner1:hover {
	background-position: 0 -200px;
}

#xpopupGalleryOverview a.inner1:link img,
#xpopupGalleryOverview a.inner1:visited img,
#xpopupGalleryOverview a.inner1:hover img {
	opacity: 0.8;
	-moz-opacity: 0.8;
	filter: alpha(opacity=80);
}

#xpopupGalleryOverview a.inner1:hover img {
	opacity: 1;
	-moz-opacity: 1;
	filter: alpha(opacity=100);
}

#xpopupGalleryOverview span {
	display: block;
}

#xpopupGalleryOverview span.image {
	margin: 30px 20px;
	text-align: center;
}

/* << */


/* >> Stylenames */
.greyRow {
	background: $color-light-greybox;
	border: 1px solid $color-border-grey;
	border-left: 0;
	border-right: 0;
}

.formFieldDescription {
	color: $color-grey;
	padding-top: emCalc(5px);
}

/* << */

/* >> Magnific popup overwrites */
.mfp-iframe-scaler iframe {
	background: transparent !important;
	box-shadow: none !important;
}

/* << */

.banner,
.massDataPagerTop {
	display: none;
}