/* ============================================================================
   Component: Popup
   ========================================================================= */

$popup-default-head-bg: $color-light  !default;
$popup-default-body-bg: #f7f9fa !default;
$popup-default-body-border: #dcdcdc !default;
$popup-grid-selection-bg: $color-light  !default;
$popup-iframeholder-max-width: null !default;

.mfp-iframe-holder .mfp-content {
	max-width: $popup-iframeholder-max-width;
}

.video-popup {
	position: relative;
	padding: 40px 0;
	width: auto;
	max-width: 500px;
	margin: 20px auto;

	.mediaplayerShell {
		margin-bottom: 0;
	}

	@media only screen and (max-width: $break-small) {
		.mediaplayerShell {
			>div:first-child {
				margin: 0 auto;
			}
		}
	}

	.mfp-close {
		color: #fff !important;
	}
}

.mfp-bg {
	opacity: 0.3;
}

.white-popup-block,
.popup-external-login {
	background: $popup-default-head-bg;
	text-align: left;
	max-width: rem-calc(500px);
	margin: rem-calc(40px) auto;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

	.head {
		padding: rem-calc(20px);
	}

	.content {
		padding: rem-calc(20px);
		background: $popup-default-body-bg;
		border-top: 1px solid $popup-default-body-border;
	}

	.headline {
		font-size: rem-calc(22px);
		font-weight: $regular;
		margin-bottom: rem-calc(15px);
	}

	.description {
		font-size: rem-calc(14px);
		line-height: rem-calc(21px);
	}

	.button {
		margin-bottom: 0;
	}

	input,
	textarea {
		margin-top: rem-calc(10px);
		border-radius: 4px;
	}

	label {
		padding: rem-calc(7px) 0 0;
	}

	.abideError {
		font-size: rem-calc(14px);
	}
}

.popup-external-login {
	max-width: rem-calc(700px);

	.head {
		padding: rem-calc(12px) rem-calc(10px);
	}

	.popup-external-login-headline {
		font-weight: $semi-bold;
		margin: 0 0 rem-calc(20px);
	}

	.popup-external-login-link {
		display: block;
		font-weight: $semi-bold;
	}
}

.popup-grid-selection {
	background: $popup-grid-selection-bg;
	text-align: left;
	max-width: rem-calc(800px);
	padding: rem-calc(20px);
	margin: rem-calc(40px) auto;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

	.popup-grid-selection-headline {
		font-size: rem-calc(20px);
		font-weight: $semi-bold;
	}

	h2 {
		font-size: rem-calc(30px);
	}

	h3 {
		font-size: rem-calc(24px);
	}

	.font-icon {
		color: $color-iron-dark;

		&:before {
			font-size: rem-calc(100px);
			padding-top: rem-calc(20px);
			padding-bottom: rem-calc(5px);
		}
	}

	.mfp-close {
		display: none !important;
	}

	.border-right {
		border-color: $popup-default-body-border;
	}
}

.popup-grid-selection-column {
	padding: 0 rem-calc(10px) em-calc(10px);
	border-radius: 4px;
	@include single-transition(background);

	&:hover {
		background: #F6F7F7;
	}
}

.mylivingapps-popup-copy {
	.open-accounts-link {
		margin: 10px 0px 3px 0px;
	}

	.accounts-input {
		display: block;
		transition: height .5s ease-in-out;

		&.closed {
			height: 0;
			overflow: hidden;
		}

		&.open {
			height: 5.75rem;
			overflow: hidden;
		}

	}
}

.action {
	padding-bottom: rem-calc(15px);
	margin-bottom: rem-calc(10px);
}