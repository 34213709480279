/* ============================================================================
   Component: Sidenav
   ========================================================================= */

$sidenav-padding: 0 !default;
$sidenav-margin: 0 !default;
$sidenav-item-color: $color-dove-gray !default;
$sidenav-item-font-size: null !default;
$sidenav-item-font-weight: $semi-bold !default;
$sidenav-item-padding: rem-calc(8px) rem-calc(16px) !default;
$sidenav-item-margin: 0 0 rem-calc(15px) 0 !default;
$sidenav-item-background: null !default;
$sidenav-item-hover-background: $color-iron-dark !default;
$sidenav-item-active-background: $sidenav-item-hover-background !default;

.sidenav {
	padding: $sidenav-padding;
	margin: $sidenav-margin;

	li {

		>a {
			display: inline-block;
			cursor: pointer;
			width: 100%;
			text-decoration: none;
			color: $sidenav-item-color;
			font-size: $sidenav-item-font-size;
			font-weight: $sidenav-item-font-weight;
			padding: $sidenav-item-padding;
			margin: $sidenav-item-margin;
			background: $sidenav-item-background;
			border-radius: 4px;
			@include single-transition(background);

			&:hover {
				background: $sidenav-item-hover-background;
			}

		}

		&.sidenav__item--inPath,
		&.sidenav__item--here {
			>.sidenav__subcategories {
				display: block;
			}
		}

		&.sidenav__item--here {
			>a {
				background: $sidenav-item-active-background;
			}
		}
	}

	.sidenav__subcategories {
		display: none;
		padding-left: rem-calc(20px);
	}
}

.mylivingapps-inputdata {
	.columns.sidemenu:first-child {
		margin: 0 0 10px;

		.row {
			display: flex;
			flex-direction: row;

			.columns {
				width: auto;
				margin: 0;


				.action {
					padding: $sidenav-padding;
					margin: $sidenav-margin;

					a.button {
						margin-bottom: 0;
					}
				}

				.sidenav {
					display: flex;
					gap: 10px;
					flex-wrap: wrap;
					align-items: center;
					justify-content: flex-start;

					&>li>a {
						margin: 0;
					}
				}
			}
		}
	}
}