/* ============================================================================
   Page: Register Page
   ========================================================================= */

.pageStyle_register {
	@extend %background-light;

	.headline1 {
		@extend .headline-account;
	}

	#head,
	#footer {
		display: none;
	}
}