/* ============================================================================
   Component: Filter
   ========================================================================= */

$filter-padding: 0 !default;
$filter-margin: 0 !default;
$filter-item-color: $color-dove-gray  !default;
$filter-item-font-size: null !default;
$filter-item-font-weight: $semi-bold  !default;
$filter-item-padding: 0 rem-calc(16px) !default;
$filter-item-margin: 0 0 rem-calc(5px) 0 !default;
$filter-item-background: null !default;
$filter-item-hover-background: null !default;
$filter-item-active-background: null !default;
$filter-item-hover-color: darken($color-dove-gray, 8%) !default;

.filter {
	padding: $filter-padding;
	margin: $filter-margin;

	>a {
		text-decoration: none;
	}

	li {
		cursor: pointer;
		color: $filter-item-color;
		font-size: $filter-item-font-size;
		font-weight: $filter-item-font-weight;
		padding: $filter-item-padding;
		margin: $filter-item-margin;
		background: $filter-item-background;
		border-radius: 4px;
		@include single-transition(background);

		&:hover {
			color: $filter-item-hover-color;
			background: $filter-item-hover-background;

			label {
				color: $filter-item-hover-color;
			}
		}

		&.active {
			background: $filter-item-active-background;
		}
	}

	label {
		cursor: pointer;
		color: $filter-item-color;
		padding: 0;
	}
}