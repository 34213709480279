/* ============================================================================
   Component: Footer
   ========================================================================= */

$footer-height: rem-calc(55px) !default;
$footer-color: $color-grey  !default;
$footer-background: darken($color-athens-gray, 3%) !default;

#wrapper {
	&:after {
		content: "";
		display: block;
		height: $footer-height + rem-calc(20px);
	}
}

#footer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: $footer-height;
	line-height: $footer-height;
	color: $footer-color;
	background: $footer-background;
	border-top: 1px solid $color-border-grey;
	box-sizing: content-box;

	a {
		color: $footer-color;
		white-space: nowrap;

		.font-icon:before {
			font-size: 15px;
			padding-top: emCalc(5px);
		}
	}

	.logo {
		float: left;
		*width: 220px !important;
	}
}

.footer-nav {
	padding: 0;
	margin: 0;
	text-align: center;

	&--inline {
		li {
			display: inline-block;
			margin-right: rem-calc(15px);

			&:last-child {
				margin-right: 0;
			}
		}
	}
}