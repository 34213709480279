/* ============================================================================
   Component: Category-Manager
   ========================================================================= */
#category-manager-target {
	height: 100%;
}

#category-manager-search-only {
	padding: 7px 18px 7px 4px;
}

.categorie-manager-base {
	@extend .white-popup-block;
	max-width: inherit;
	min-width: 980px;
	width: 50vw;
	min-height: 700px;
	height: 60vh;
	margin-top: 0;
	margin-bottom: 0;
	user-select: none;
	background-color: #fff;

	.mfp-close-changes-happends {
		display: none;
	}
}

/*<<*/