/**
 * Nav Variables
 */
$flOuterShell-float: left !default;
$flOuterShell-margin: 0 0 0 rem-calc(300px) !default;
$flNavCHS1-width: 100% !default;
$flNavCHS1-padding: rem-calc(20px) 0 !default;
$flNavEl1-padding: 0 rem-calc(8px) !default;
$flNavEl1-margin: 0 rem-calc(8px) !default;
$flNavEl1-font-size: rem-calc(18px) !default;
$flNavEl1-border: 2px solid #fff !default;
$flNavEl1-here-color: $color-dark !default;
$flNavEl1-here-background: 0 !default;
$flNavEl1-here-border: 2px solid $color-primary !default;
$flNavEl1-here-border-radius: 0 !default;


.flOuterShell_floats .flNavCHS_1 {
	// max-width: $grid-width;
	margin: $grid-alignment;
	overflow: hidden;
}

.flOuterShell_floats .flNavCHS_1 {
	max-width: $flNavCHS1-width;
	overflow: visible;
}

.flOuterShell .jsFlNav1,
.flOuterShell .jsFlNav2 {
	position: relative !important;
}

.flOuterShell .closed {
	max-height: none;
}

.nav-toggle {
	display: none;
}

/* Erste Ebene Navigation aeusserer Navigationskontainer */
.flOuterShell,
.flOuterShell .des1 {}

/* Anpassungen der erste Ebene Navigationspalte */
.flOuterShell .des1 {
	position: relative;
	z-index: 1;
}

.flNavCHS_1 {
	transition: background 500ms ease-out, box-shadow 500ms ease-out;
	transform: translatez(0);
	background: #fff;
}

/* Einstellungen fuer die erste Ebene Navigation */
.flNavCHS_1,
.flNavCHS_2,
.flNavCHS_3 {}

.flOuterShell_floats .flNavCHS_1,
.flOuterShell_floats .flNavCHS_2 {
	@extend .clearfix;
}

.flOuterShell_floats .flNavCHS_2,
.flOuterShell_floats .flNavCHS_3 {
	@extend .clearfix;
}

.flOuterShell_floats .flNavCHS_1 {
	padding: $flNavCHS1-padding;
}

.flOuterShell_floats .flNavCHS_2 {}

.flOuterShell_floats .nav_2 {
	max-width: $flNavCHS1-width;
	border-top: 1px solid $color-border-grey;
	padding: rem-calc(10px) rem-calc(30px);
}

.flNavNS_1,
.flNavNS_2,
.flNavNS_3 {}

/* Einstellung der Trennelemente */
.flOuterShell img.sep {
	display: none;
}

/* float mode */
.flOuterShell_floats .nav_1 {
	width: auto;
	float: $flOuterShell-float;
	margin: $flOuterShell-margin;
}

.flOuterShell_floats li {
	float: left;
}

.flOuterShell_floats .flNavCHS_1 {}

/* Ebene 0, Ebene 1 */
.flNavEl div.noLink,
.flNavEl a,
.flNavEl a:link,
.flNavEl a:visited,
.flNavEl a:hover {
	text-decoration: none;
	white-space: nowrap;
}

.flNavNS_0 div.noLink,
.flNavNS_0 div.noLink:hover,
.flNavNS_0 a,
.flNavNS_0 a:link,
.flNavNS_0 a:visited,
.flNavNS_0 a:hover,
.flNavNS_1 div.noLink,
.flNavNS_1 a,
.flNavNS_1 a:link,
.flNavNS_1 a:visited,
.flNavNS_1 a:hover {
	display: block;
	line-height: emCalc(30px);
	font-size: $flNavEl1-font-size;
	font-weight: $semi-bold;
	color: $color-dark;
	padding: $flNavEl1-padding;
	margin: $flNavEl1-margin;
	border-bottom: $flNavEl1-border;
	border-radius: 4px;
}

.flNavNS_0 div.noLink.notHere,
.flNavNS_1 div.noLink.notHere {
	padding: 0;
	margin: 0;
	border-bottom: 0;
}

.flNavNS_0 div.noLink,
.flNavNS_0 a,
.flNavNS_0 a:link,
.flNavNS_0 a:visited,
.flNavNS_0 a:hover {
	display: none;
}

.flNavEl_0_normal a:hover,
.flNavEl_0_inPath a:hover,
.flNavEl_1_normal a:hover,
.flNavEl_1_inPath a:hover,
.flNavEl_0_here div.noLink,
.flNavEl_1_here div.noLink,
.flNavNS_0 div.noLink a:hover,
.flNavNS_1 div.noLink a:hover {
	color: $flNavEl1-here-color;
	background: $flNavEl1-here-background;
	border-bottom: $flNavEl1-here-border;
	border-radius: $flNavEl1-here-border-radius;
}

/* Ebene 2 */
.flNavNS_2 div.noLink,
.flNavNS_2 a,
.flNavNS_2 a:link,
.flNavNS_2 a:visited,
.flNavNS_2 a:hover {
	display: block;
	color: $color-primary;
	font-size: rem-calc(16px);
	padding: rem-calc(5px) 0;
	margin-right: rem-calc(15px);
}

.flNavEl_2_normal a:hover,
.flNavEl_2_inPath a:hover,
.flNavEl_2_here div.noLink,
.flNavNS_2 div.noLink a:hover {
	color: $color-primary-hover;
}

.flNavEl_2_here div.noLink,
.flNavNS_2 div.noLink a:hover {
	font-weight: $semi-bold;
}


/* >> Mobile Nav Trigger (container) */
// .mobileNavCont {
// 	display: none;
// 	width: auto;
// 	top: 39px;
// 	left: $w-std-gap;
// 	z-index: 1000;
// }

// .mobileNav .pmt .icon {
// 	@include sprite($icons-menu);
// }

// .mobileNav:target .pmt .icon,
// .mobileNav.active .pmt .icon {
// 	@include sprite-position($icons-close);
// }
/* << */

@media only screen and (max-width: $break-medium) {
	.flOuterShell_floats .js-showFlyout {
		display: none;
	}

	.flOuterShell_floats {
		position: relative;
		z-index: 1010;
	}

	.flOuterShell_floats .flNavCHS_1 {
		position: relative;
		padding: 0;
	}

	.flOuterShell_floats .flNavCHS_2 {
		margin-top: 0;
		background: $color-greybox;
		border-bottom: 1px solid $color-border-grey;
	}

	.flOuterShell .nav_1 {
		display: none;
		width: 100%;
		float: none;
	}

	.flOuterShell .jsFlNav1,
	.flOuterShell .jsFlNav2 {
		max-height: 0;
		position: absolute !important;
		display: block;
		overflow: hidden;
		zoom: 1;
		padding: 0;
		margin: 0;
	}

	.flOuterShell .jsFlNav1 {
		z-index: 30;
	}

	.flOuterShell .jsFlNav2 {
		width: 96%;
		z-index: 29;
		top: 50px;
		left: $grid-gutter-pixel;
		background: #fff;
		border: 1px solid $color-border-grey;
		border-top: 0 none;
		margin: 0;
	}

	.flOuterShell .opened {
		max-height: 9999px;
	}

	.flOuterShell .closed {
		border-color: #fff;
	}

	.flOuterShell .jsFlNav2.closed {}

	.nav-toggle {
		display: block;
		user-select: none;
	}

	#nav-toggle1 {
		position: absolute;
		top: -53px;
		right: 0;
		width: 62px;
		height: 53px;
		overflow: hidden;
	}

	#nav-toggle1 .font-icon {
		display: block;
		margin: 10px 0 0 emCalc(15px, 22px);
		color: $color-dark;
		font-size: emCalc(22px);
		line-height: 1em;
	}

	.flOuterShell_floats .nav_1 li {
		width: 100%;
	}

	.flOuterShell_floats .nav_2 {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.flOuterShell_floats .nav_2 li {
		width: 100%;
	}

	.flNavNS_0 div.noLink,
	.flNavNS_0 a,
	.flNavNS_0 a:link,
	.flNavNS_0 a:visited,
	.flNavNS_0 a:hover,
	.flNavNS_1 div.noLink,
	.flNavNS_1 a,
	.flNavNS_1 a:link,
	.flNavNS_1 a:visited,
	.flNavNS_1 a:hover {
		border-radius: 0;
		font-size: rem-calc(20px);
		line-height: rem-calc(24px);
		color: $color-dark;
		padding: rem-calc(13px) rem-calc(10px) rem-calc(13px) 0;
		margin: 0;
		border-bottom: 1px solid $color-border-grey;
		background: $color-greybox;
	}

	.flNavNS_0 div.noLink,
	.flNavNS_0 a,
	.flNavNS_0 a:link,
	.flNavNS_0 a:visited,
	.flNavNS_0 a:hover {}

	.flOuterShell_floats .nav_1 li:first-child div.noLink,
	.flOuterShell_floats .nav_1 li:first-child a {}

	.flOuterShell_floats .nav_1 .noLink .inner,
	.flOuterShell_floats .nav_1 a .inner {
		display: block;
		padding-left: 1.5em;
	}

	.flNavEl_0_normal a:hover,
	.flNavEl_0_inPath a:hover,
	.flNavEl_1_normal a:hover,
	.flNavEl_1_inPath a,
	.flNavEl_1_inPath a:link,
	.flNavEl_1_inPath a:visited,
	.flNavEl_1_inPath a:hover,
	.flNavEl_0_here div.noLink,
	.flNavEl_1_here div.noLink {
		box-shadow: none;
		color: $color-primary;
	}

	.flNavNS_2 div.noLink,
	.flNavNS_2 a,
	.flNavNS_2 a:link,
	.flNavNS_2 a:visited,
	.flNavNS_2 a:hover {}

	.flNavEl_2_normal a:hover,
	.flNavEl_2_inPath a,
	.flNavEl_2_inPath a:link,
	.flNavEl_2_inPath a:visited,
	.flNavEl_2_inPath a:hover,
	.flNavEl_2_here div.noLink {
		color: $color-primary;
	}

	.flOuterShell_floats .nav_2 .noLink .inner,
	.flOuterShell_floats .nav_2 a .inner {
		display: block;
		line-height: emCalc(22px);
		padding-left: emCalc(17px);
	}
}

@media only screen and (max-width: $break-small) {
	.flOuterShell .jsFlNav2 {
		width: auto;
		left: 0;
		margin: 0 $grid-gutter-pixel;
	}
}