/* ============================================================================
   Component: Typed Views wizard. Typed views are special formbuilder view representations.
   ========================================================================= */


#livingapps-form .typed-views-wizard {
	position: relative;
	ul {
		list-style: none;
		background: transparent;
		margin: 0 0 30px;
		li {
			display: block;
			position: relative;
			background: transparent;
		}
	}
}
#livingapps-form .typed-views-wizard-buttons {
	position: relative;
	height: 50px;
	.llft-nextcontrol, .llft-finishcontrol {
		position: absolute;
		top: 0;
		right: 0;	
	}
	.llft-prevcontrol {
		input[type="submit"] {
			background: #aaa;
			&:hover {
				background: #8a8a8a;			
			}
		}
	}
	.llft-finishcontrol {
		input[type="submit"] {
			background: #5ac147;
			&:hover {
				background: #449c33;
			}
		}
	}
}

@media screen and (max-width: $break-small) {
}